<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-03T12:08:26+02:00
-->

<template>
  <div class="d-flex flex-column mx-2">

    <div class="d-flex align-items-center">
      <b-button class="p-0 transparent_button mr-2" @click="goBack()">
        <img src="@/assets/img/png/ico_goback.png" style="width:3em"/>
      </b-button>
      <div class="mainTitle">
        <div id="mtitle">{{ theme.name_en }}</div>
        <div id="stitle">{{ articles.length }} articles</div>
        <hr>
      </div>
    </div>

    <div class="d-flex align-items-start">
      <div class="mr-3">
        <b-button class="transparent_button m-0 p-0" @click="view = 'grid'">
          <div :class="{'modeButton': true, 'activeMode': view == 'grid'}">
            <img src="@/assets/img/svg/ico_grid.svg" title="grid">
          </div>
        </b-button>
        <b-button class="transparent_button m-0 p-0" @click="view = 'list'">
          <div :class="{'modeButton': true, 'activeMode': view == 'list'}">
            <img src="@/assets/img/svg/ico_list.svg" title="list">
          </div>
        </b-button>
      </div>
      <div v-if="view == 'grid'"  class="d-flex align-items-center ml-auto">
        <span class="mr-2 text-nowrap">Sort by :</span>
        <b-form-select v-model="sorting_by" :options="sortinglist"></b-form-select>
      </div>
    </div>


    <hr v-if="view == 'grid'" class="w-100">

    <div v-if="articles">

      <articlesGrid v-if="view == 'grid'" mode="theme" :articles="sortingArticles" :no_image="no_image" @removeArticleFromTheme="openArticleFromTheme($event.article, $event.index)" @addToNewsletter="addToNewsletter($event.article, $event.index)"></articlesGrid>

      <articlesList v-if="view == 'list'" mode="theme" :articles="sortingArticles" :no_image="no_image" @removeArticleFromTheme="openArticleFromTheme($event.article, $event.index)" @addToNewsletter="addToNewsletter($event.article, $event.index)"></articlesList>

    </div>

    <b-modal size="xl" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showRemoveArticle">
      <b-button class="crossButton" @click="showRemoveArticle = false">✕</b-button>

      <p>Do you really want to remove this article from this theme ?</p>

      <div v-if="current_article" class="my-3">
        <p>Name: <span class="largeTitle">{{ current_article.value.title }}</span></p>
      </div>

      <hr>

      <div class="d-flex" id="buttons">
        <b-button class="largeColoredButton mx-auto" @click="showRemoveArticle = false">CANCEL</b-button>
        <b-button class="largeColoredButton mx-auto" @click="removeArticle()">REMOVE </b-button>
      </div>

    </b-modal>


  </div>
</template>

<script>

////////////////// Import //////////////////

import Articles from '@/services/articles'
const articlesService = new Articles();

import Newsletters from '@/services/newsletters'
const newslettersService = new Newsletters();

import articlesList from '@/components/articles/articlesList'
import articlesGrid from '@/components/articles/articlesGrid'

////////////////// Functions //////////////////

function getList(self){
  articlesService.getListFromTheme(self.theme._id).then(function(result) {
    self.articles = result.body.articles;
    self.sources = result.body.sources;

    for (let article of self.articles) {
      // Get source info
      let source = self.sources.find(a => a.uri == article.metadata.source_uri);
      // console.log("source = " + JSON.stringify(source));
      if(source) {
        article.source_name = source.name;
        article.source_country = (source.location)? source.location.country.name : null;
      }

      // Newsletter
      article.inNewsletter = self.newsletter.articles.findIndex(a => a.uri == article.uri) > -1;

      // article.source_name = source?.name;
      // article.source_country = source?.location?.country?.name;
    }

    self.sorting_by = "date";

    self.loading = false;
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
  });
}

function getNewsLetter(self, callback) {
  newslettersService.getCurrent().then(function(result) {
    self.newsletter = result.body.news;
    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    callback();
  });
}


export default {
  name: 'articles',
  props: ['theme','themes','no_image'],
  data () {
    return {
      loading: true,
      showRemoveArticle: false,
      articles: [],
      sources: [],
      sortinglist: [],
      sorting_by: null,
      view: "grid",
      current_article: null,
      newsletter: null
    }
  },
  components: {
    articlesList,
    articlesGrid
  },
  computed: {
    sortingArticles: function() {
      console.log(this.sorting_by);
      switch (this.sorting_by) {
        case 'date':
          return this.articles.sort((a,b) => {return (a.metadata.date <  b.metadata.date)? 1 : -1;});
        case 'media':
          return this.articles.sort((a,b) => {return (a.source_name <  b.source_name)? -1 : 1;});
        case 'language':
          return this.articles.sort((a,b) => {return (a.metadata.language <  b.metadata.language)? -1 : 1;});
        default:
          return 0
      }
    },
  },
  created: function() {
    let self = this;

    this.sortinglist = [
      { value: 'date', text: "Date"},
      { value: 'media', text: "Media"},
      { value: 'language', text: "Language"}
    ]

    getNewsLetter(self, function() {
      getList(self);
    })
  },
  methods: {
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR');
    },
    goBack() {
      this.$emit('goBack')
    },
    openArticleFromTheme(article, index){
      this.current_article = {index: index, value: article};
      this.showRemoveArticle = true;
    },
    removeArticle(){
      let self = this;
      let article = this.current_article.value;
      let index = this.current_article.index;

      let theme_id = this.theme._id;

      articlesService.removeToTheme(article.uri, theme_id, true).then(function(result) {
        self.articles.splice(index, 1);
        self.$bvToast.toast("Articles have been removed from this theme", self.__toaster_opts('success', 'Success'))
        self.showRemoveArticle = false;
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });

    },
    addToNewsletter(article, index){
      let self = this;
      newslettersService.addArticleToCurrent(article.uri).then(function(result) {
        if(result.body.status == "removed") {
          article.inNewsletter = false;
          self.$bvToast.toast("Article removed from the newsletter", self.__toaster_opts('success', 'Success'))
        } else {
          article.inNewsletter = true;
          self.$bvToast.toast("Article added to the newsletter", self.__toaster_opts('success', 'Success'))
        }

        self.$set(self.articles, index, article)

        self.newsletter = result.body.news;

      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });
    },

  }
}

</script>

<style lang="scss">

@import '@/assets/css/common.scss';

.themeClass {
  margin: 1em auto 1em auto;
  img {
    width: 3em;
    height: 3em;
  }
}

.articleContenerClass {
  // height: 30em;
  width: 30%;

}

.modeButton {
  border-radius: 2px;
  padding: 2px 3px 2px 3px;

  img {
    width: 1.5em;
    height: 1.5em;
    filter: invert(63%) sepia(33%) saturate(470%) hue-rotate(177deg) brightness(75%) contrast(91%);
    opacity: 0.8;
  }
}

.activeMode {
  // background-color: $color-1-light;
  img {
    // filter: brightness(0) invert(1);
    filter: invert(23%) sepia(46%) saturate(373%) hue-rotate(177deg) brightness(93%) contrast(93%);
    opacity: 1;
  }
}

</style>
