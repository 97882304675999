<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-03T09:38:34+02:00
-->

<template>
  <div class="d-flex flex-column mx-2" style="position:relative" v-if="articles && articles.length > 0">
    <b-table class="mt-3" :items="articles" :fields="fieldsTable">
      <template v-slot:cell(title)="data">
        <div class="d-flex align-items-start" style="cursor: pointer" @click="openArticle(data.item)">
          <div>
            <b-img-lazy id="listArticleImage" v-if="data.item.uri && errorLoadingImageList.indexOf(data.item.uri) == -1" crossorigin="anonymous" :src="imageBaseURL + data.item.uri" @error.native="errorLoadingImage(data.item.uri)" fluid></b-img-lazy>
            <img id="listArticleImage" v-if="errorLoadingImageList.indexOf(data.item.uri) > -1" crossorigin="anonymous" :src="no_image" style="opacity:0.3"/>
          </div>
          <div id="listTitle" :title="data.item.title">
            {{ data.item.title }}
          </div>
        </div>
      </template>
      <template v-slot:cell(source_name)="data">
        {{ data.item.source_name }}
      </template>
      <template v-slot:cell(metadata.date)="data">
        {{ toStringDate(data.item.metadata.date) }}
      </template>
      <template v-slot:cell(metadata.language)="data">
        {{ displayLanguage(data.item.metadata.language) }}
      </template>
      <template v-slot:cell(is_validated)="data">
        {{ (data.item.is_validated)? 'YES' : 'NO' }}
      </template>
      <template v-slot:cell(action)="data">
        <div class="d-flex align-items-bottom">
          <!-- <b-dropdown v-if="mode == 'topic'" class="myDropdown my-auto" dropleft no-caret>
            <template #button-content>
              <img src="@/assets/img/svg/ico_threedots.svg" style="height:1em;">
            </template>
            <b-dropdown-item-button class="largeTitle"  @click="removeArticle(data.item, data.index)">Remove</b-dropdown-item-button>
            <b-dropdown-item-button class="largeTitle"  @click="openArticleTo(data.item, data.index)">Themes</b-dropdown-item-button>
          </b-dropdown>

          <b-dropdown v-if="mode == 'theme'" class="myDropdown" dropleft no-caret>
            <template #button-content>
              <img src="@/assets/img/svg/ico_threedots.svg" style="height:1em;">
            </template>
            <b-dropdown-item-button class="largeTitle"  @click="removeArticleFromTheme(data.item, data.index)">Remove from this theme</b-dropdown-item-button>
          </b-dropdown> -->


          <div v-if="mode == 'topic'" class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-1" @click="removeArticle(data.item, data.index)" title="Remove" style="cursor:pointer;">
              <img src="@/assets/img/png/ico_remove.png" style="height:1.3em;">
            </div>
            <div class="d-flex align-items-center" @click="openArticleTo(data.item, data.index)" title="Themes" style="cursor:pointer;">
              <img src="@/assets/img/png/ico_theme.png" style="height:1.3em;">
            </div>
          </div>

          <div v-if="mode == 'theme'" class="d-flex align-items-center">
            <div class="d-flex align-items-center" @click="removeArticleFromTheme(data.item, data.index)" title="Remove from this theme" style="cursor:pointer;">
              <img src="@/assets/img/png/ico_theme.png" style="height:1.3em;">
            </div>
            <div class="d-flex align-items-center" @click="addToNewsletter(data.item, data.index)" :title="(data.item.inNewsletter)? 'Remove from the next newsletter' : 'Add to the next newsletter'" style="cursor:pointer;">
              <img v-if="data.item.inNewsletter" src="@/assets/img/png/ico_mail_full.png" style="height:1.3em;">
              <img v-else src="@/assets/img/png/ico_mail_outlined.png" style="height:1.3em;">
            </div>
          </div>

          <div v-if="mode == 'newsletter'" class="d-flex align-items-center">
            <div class="d-flex align-items-center" @click="addToNewsletter(data.item, data.index)" :title="(data.item.inNewsletter)? 'Remove from the next newsletter' : 'Add to the next newsletter'" style="cursor:pointer;">
              <img v-if="data.item.inNewsletter" src="@/assets/img/png/ico_mail_full.png" style="height:1.3em;">
              <img v-else src="@/assets/img/png/ico_mail_outlined.png" style="height:1.3em;">
            </div>
          </div>


        </div>

      </template>
    </b-table>

  </div>
</template>

<script>

////////////////// Import //////////////////

////////////////// Functions //////////////////

export default {
  name: 'articlesList',
  props: ['articles','mode','no_image'],
  data () {
    return {
      errorLoadingImageList: []
    }
  },
  computed: {
    fieldsTable: function () {
      return [
        { key: 'title', label: "Title", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'source_name', label: "Media", sortable: true, thClass: 'listTableSourceNameClass'},
        { key: 'metadata.date', label: "Date", sortable: true},
        { key: 'metadata.language', label: "Language", sortable: true},
        { key: 'is_validated', label: "Validated", sortable: true},
        { key: 'action', label: ""},
      ];
    },
    imageBaseURL: function() {
      return this.$_url + '/images/'
    },
  },
  created: function() {

  },
  methods: {
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR');
    },
    errorLoadingImage(uri){
      this.errorLoadingImageList.push(uri);
    },
    // checkAllArticles(){
    //   for (let article of this.articles) {
    //     this.$set(article, 'isChecked', this.checkAll)
    //   }
    // },
    displayLanguage(language){
      switch (language) {
        case "fra":
          return 'French'
        case "eng":
          return 'English'
        default:
          return language
      }
    },
    removeArticle(article, index){
      this.$emit('removeArticle', {article: article, index: index})
    },
    openArticleTo(article, index){
      this.$emit('openArticleTo', {article: article, index: index})
    },
    openArticle(article){
      if(!article.article_url) this.$bvToast.toast('Missing article url', this.__toaster_opts('error', 'Error'))
      window.open(article.article_url, '_blank');
    },
    removeArticleFromTheme(article, index){
      this.$emit('removeArticleFromTheme', {article: article, index: index})
    },
    addToNewsletter(article, index){
      this.$emit('addToNewsletter', {article: article, index: index})
    }
  }
}

</script>

<style lang="scss">

@import '@/assets/css/common.scss';


#listTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  // white-space:nowrap;
  text-overflow:ellipsis !important;
  overflow: hidden;
  max-width:35em;
}

.listTableTitleClass {
  width: 45em;
}

#listSourceName {
  white-space:nowrap;
  text-overflow:ellipsis !important;
  overflow: hidden;
  max-width:15em;
}

.listTableSourceNameClass {
  width: 16em;
}

#listArticleImage {
  width: 8em;
  height: 3em;
  object-fit: cover;
  margin-right: 1em;
}

</style>
