<!--
@Author: laborde
@Date:   2022-03-24T09:31:25+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-03T09:35:20+02:00
-->

<template>
  <div id="menu" class="d-flex align-items-center">

    <div id="logo" class="d-flex ml-2" @click="goTo('home')">
      <img src="@/assets/img/png/logo.png" style="height:4em;cursor: pointer;">
    </div>

    <div class="d-flex align-items-center justify-content-center mx-auto">

      <b-button @click="goTo('home')" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('home') }]" tag="button">Home</b-button>
      <b-button @click="goTo('topics')" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('topics') }]" tag="button">Topics</b-button>
      <b-button @click="goTo('themes')" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('themes') }]" tag="button">Themes</b-button>
      <b-button @click="goTo('users')" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('users') }]" tag="button">Users</b-button>
      <b-button @click="goTo('teams')" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('teams') }]" tag="button">Teams</b-button>
      <b-button @click="goTo('newsletters')" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('newsletters') }]" tag="button">Newsletters</b-button>

    </div>

    <div id="logout" class="d-flex">
      <b-button  @click="logout()" class="transparent_button menu-button my-auto" tag="button">Log out</b-button>
    </div>


  </div>


</template>

<script>

export default {
  name: 'topmenu',
  data () {
    return {

    }
  },
  computed: {
    routeName() {
      return this.$route.name
    }
  },
  created: function() {

  },
  methods: {
    activePage(name){
      return this.routeName && this.routeName == name;
    },
    goTo(name){
      if(!this.routeName || this.routeName != name) this.$router.push({ name: name })
    },
    logout () {
      window.$cookies.remove('access_token_sba');
      this.$router.push({ name: 'login' });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/css/common.scss';

#menu {
  height: 3em;
  width: 100%;
  position: relative;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background-color: #d9dbf1;
  overflow-y: hidden;
  border: 1em;
}

img {
  height: 3em;
}

.menu-button {
  color: $color-1;
  font-family: 'Raleway-SemiBold';
  margin-left:0.9em;
  margin-right:0.9em;
  padding-left:0.1em;
  padding-right:0.1em;

  &:hover:enabled, &:focus:enabled {
    color: $color-1-darker;
  }

}

.menu-button-active {
  color: $color-1-darker;
}

#logout {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
}

#logo {
  position: absolute;
  height: 100%;
  top: -0.5em;
  left: 0;
}


</style>
