/**
 * @Author: laborde
 * @Date:   2022-03-23T10:01:49+01:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-09-16T09:40:45+02:00
 */

'use strict'

import Vue from 'vue'

export default class genericClass {

  getList() {
    return Vue.http.get(Vue.prototype.$_url + "/users/list");
  }

  getCSV() {
    return Vue.http.get(Vue.prototype.$_url + "/users/csv");
  }

  getUsernames() {
    return Vue.http.get(Vue.prototype.$_url + "/users/usernames");
  }

  get(id) {
    return Vue.http.get(Vue.prototype.$_url + "/users/" + id);
  }

  create(user) {
    return Vue.http.put(Vue.prototype.$_url + "/users", {user : user});
  }

  sendCreationEmail(data) {
    return Vue.http.post(Vue.prototype.$_url + "/users/email/creation", data);
  }

  update(user) {
    return Vue.http.post(Vue.prototype.$_url + "/users", {user : user});
  }

  resetPassword(data) {
    return Vue.http.post(Vue.prototype.$_url + "/users/password/reset", data);
  }

  getPlan(id) {
    return Vue.http.get(Vue.prototype.$_url + "/users/"+ id + "/plan");
  }

  setPlan(id, plan, language = 'en') {
    return Vue.http.post(Vue.prototype.$_url + "/users/"+ id + "/plan", { plan: plan, language: language });
  }

  updateInvoices(id, invoices) {
    return Vue.http.post(Vue.prototype.$_url + "/users/"+ id + "/plan/invoices", { invoices: invoices });
  }

  getStats(id) {
    return Vue.http.get(Vue.prototype.$_url + "/users/"+ id + "/stats");
  }

  getConnectionHistory(id) {
    return Vue.http.get(Vue.prototype.$_url + "/users/"+ id + "/connections/history");
  }

}
