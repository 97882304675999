<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-07-26T15:02:58+02:00
-->

<template>
  <div id="main">
    <line-loader :loading="loading"></line-loader>

    <div v-if="!loading && themes">
      <div v-if="step == 1" class="d-flex flex-wrap">

        <div class="mainTitle">
          <div id="mtitle">Themes</div>
          <div id="stitle">{{ themes.length }} themes</div>
          <hr>
        </div>

        <div class="topicTileClass" v-for="theme in themes"  :key="theme._id">

          <div class="d-flex flex-column mx-auto" @click="goToTheme(theme)">
            <img v-if="!theme.__image_loading_error" id="themeImage" class="mx-auto mb-3" :src="getImage(theme.shortname)" @error.native="theme.__image_loading_error = true">
            <img v-else id="themeImage" class="mx-auto mb-3" src="@/assets/img/svg/no_image.svg">

            {{ theme.__image_loading_error }}

            <span class="mx-auto" id="title">{{ theme.name_en }}</span>
            <span class="mx-auto" id="title">{{ theme.articlesCount }} articles</span>
            <!-- <span class="mx-auto" id="title">{{ theme.newArticlesCount }} new articles</span> -->
          </div>

        </div>

      </div>

      <div v-if="step == 2 && current_theme">
        <list :theme="current_theme" :themes="themes" :no_image="getImage(current_theme.shortname)" @goBack="goToThemesDisplay()"></list>
      </div>

    </div>

  </div>
</template>

<script>

////////////////// Import //////////////////

import list from './articles'

import Themes from '@/services/themes'
const themesService = new Themes();

////////////////// Functions //////////////////

function getThemesList(self, callback){
  themesService.getList().then(function(result) {
    self.themes = result.body.themes;
    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
    callback();
  });
}

export default {
  name: 'Home',
  data () {
    return {
      loading: true,
      themes: null,
      themes: null,
      step: 1,
      current_theme: null
    }
  },
  components: {
    list
  },
  created: function() {
    var self = this;
    getThemesList(self, function () {
      self.loading = false;
    });
  },
  methods: {
    getImage(shortname){
      try {
        let path = require('@/assets/img/themes/'+shortname+'.jpg');
        return path;
      } catch (e) {
        return require('@/assets/img/svg/no_image.svg');
      }
    },
    goToThemesDisplay(){
      this.step = 1;
      this.current_theme = null;
    },
    goToTheme(theme){
      this.current_theme = theme;
      this.step = 2;
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';

#themeImage {
  width: 6em;
  height: 6em;
  object-fit: cover;
  margin-right: 1em;
  // border: 1px solid $color-1-light;
  box-shadow: 1px 0px 20px -1px #e5e5e5;

  border-radius: 3em;
}

</style>
