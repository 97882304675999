<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-03T09:37:12+02:00
-->

<template>
  <div :class="{'articleClass': true }" v-if="article">
    <img  id="greenCheck" v-if="mode == 'topic' && article.is_validated" title="Validated" src="@/assets/img/png/ico_check_green.png" />

    <!-- <input id="checkboxArticle" type="checkbox"> -->

    <div class="d-flex flex-column h-100 mt-1">

      <div id="clickableZone" class="d-flex flex-column h-100 mb-4" @click="openArticle(article)">
        <b-img-lazy v-if="article.uri && !image_loading_error" id="articleImage" crossorigin="anonymous" :src="'https://admin.smartbeautyinsight.com/api/v1/images/' + article.uri" @error.native="image_loading_error = true" fluid></b-img-lazy>
        <img v-else id="articleImage" crossorigin="anonymous" :src="no_image" style="opacity:0.3"/>

        <div class="d-flex align-items-start">
          <div id="mediaName">{{ article.source_name }}</div>
          <div id="articleLanguage" class="ml-auto" v-if="article.metadata.language">{{ displayLanguage(article.metadata.language) }}</div>
        </div>
        <!-- <span :class="{'showSpan': article.metadata.language != null}" id="articleMetadata">Language : {{ displayLanguage(article.metadata.language) }}</span> -->
        <!-- <span :class="{'showSpan': article.source_country != null}" id="articleMetadata">Location : {{ article.source_country }}</span> -->

        <span class="text-justify" id="title">{{ article.title }}</span>

        <span class="text-justify" id="body">{{ article.body }}</span>

      </div>

      <div class="d-flex align-items-start">
        <div id="relativeTime">
           {{ toStringDate(article.metadata.date) }}
        </div>
        <div class="ml-auto">
          <!-- <b-dropdown v-if="mode == 'topic'" class="myDropdown my-auto" dropleft no-caret>
            <template #button-content>
              <img src="@/assets/img/svg/ico_threedots.svg" style="height:1em;">
            </template>
            <b-dropdown-item class="largeTitle" @click="removeArticle()">Remove</b-dropdown-item>
            <b-dropdown-item class="largeTitle" @click="openArticleTo()">Themes</b-dropdown-item>
            <b-dropdown-item class="largeTitle" @click="openArticle(article)">Display</b-dropdown-item>
          </b-dropdown>
          <b-dropdown v-if="mode == 'theme'" class="myDropdown" dropleft no-caret>
            <template #button-content>
              <img src="@/assets/img/svg/ico_threedots.svg" style="height:1em;">
            </template>
            <b-dropdown-item class="largeTitle" @click="removeArticleFromTheme()">Remove from this theme</b-dropdown-item>
          </b-dropdown> -->

          <div v-if="mode == 'topic'" class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-1" @click="removeArticle()" title="Remove" style="cursor:pointer;">
              <img src="@/assets/img/png/ico_remove.png" style="height:1.3em;">
            </div>
            <div class="d-flex align-items-center" @click="openArticleTo()" title="Themes" style="cursor:pointer;">
              <img src="@/assets/img/png/ico_theme.png" style="height:1.3em;">
            </div>
          </div>

          <div v-if="mode == 'theme'" class="d-flex align-items-center">
            <div class="d-flex align-items-center" @click="removeArticleFromTheme()" title="Remove from this theme" style="cursor:pointer;">
              <img src="@/assets/img/png/ico_theme.png" style="height:1.3em;">
            </div>
            <div class="d-flex align-items-center" @click="addToNewsletter()" :title="(inNewsletter)? 'Remove from the next newsletter' : 'Add to the next newsletter'" style="cursor:pointer;">
              <img v-if="inNewsletter" src="@/assets/img/png/ico_mail_full.png" style="height:1.3em;">
              <img v-else src="@/assets/img/png/ico_mail_outlined.png" style="height:1.3em;">
            </div>
          </div>

        </div>
      </div>



    </div>
  </div>
</template>

<script>

////////////////// Import //////////////////

////////////////// Functions //////////////////

export default {
  name: 'articleCard',
  props: ['article','inNewsletter','mode','no_image'],
  data () {
    return {
      image_loading_error: false
    }
  },
  created: function() {

  },
  methods: {
    toStringDate(date) {
      const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'narrow' });

      let oneDay = 24*60*60*1000;
      let oneHour = 60*60*1000;

      if(date) {
        let time = Date.now() - (new Date(date)).getTime();
        // 1 days max
        if(time <= oneDay) {
          let hours = Math.round(- time / oneHour);
          return "" + rtf1.format(hours, 'hour')
        } else {
          let days = Math.round(- time / oneDay);
          return "" + rtf1.format(days, 'day')
        }
      } else {
        return '';
      }
    },
    displayLanguage(language){
      switch (language) {
        case "fra":
          return 'French'
        case "eng":
          return 'English'
        default:
          return language
      }
    },
    openArticle(article){
      console.log("article = " + JSON.stringify(article));

      if(!article.article_url) return this.$bvToast.toast('Missing article url', this.__toaster_opts('error', 'Error'))
      // this.current_article = article;
      // this.showArticle = true;
      window.open(article.article_url, '_blank');
    },
    removeArticle(){
      this.$emit('removeArticle', {})
    },
    openArticleTo(){
      this.$emit('openArticleTo', {})
    },
    removeArticleFromTheme(){
      this.$emit('removeArticleFromTheme')
    },
    addToNewsletter(){
      this.$emit('addToNewsletter')
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';

.articleClass {
  position: relative;
  height: 32em;

  color: black;

  background-color: white;
  border-radius: 5px;
  // margin: 0.5em 1em 0.5em 1em;
  padding: 1em;

  box-shadow: 1px 0px 20px -1px #e5e5e5;

  #clickableZone {
    cursor: pointer;
  }

  #greenCheck {
    height: 1.5em;
    width: 1.5em;
    position: absolute;
    top: -0.2em;
    left: -0.2em;
  }

  #checkboxArticle {
    position: absolute;
    top: 0.2em;
    left: 0.2em;
  }

  #articleImage {
    width: 100%;
    height: 11em;
    object-fit: cover;
    margin-bottom: 1em;
    // // vertical-align: middle;
    // background-position: top top;
    // background-repeat: no-repeat;
  }

  #articleMetadata {
    text-overflow: ellipsis !important;
    overflow: hidden;
    white-space:nowrap;
    color: $color-1;
    font-size: 0.8em;
    visibility: hidden;

  }

  #articleMetadata.showSpan {
    visibility: visible !important;
  }

  #title {
    font-weight: bold;
    color: $color-1;
    margin: 1em auto 1em 0;
  }

  #buttons {
    margin-top: auto;
    margin-bottom: 1em;
  }

  #relativeTime {
    color: $color-1;
    font-size: 0.9em;
  }

  #mediaName {
    color: $color-1-darker;
    font-weight: bold;
  }

  #articleLanguage {
    color: $color-1;
    font-size: 0.9em;
    font-weight: lighter;
  }

}

</style>
