<!--
@Author: laborde
@Date:   2022-04-13T13:14:50+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-09-22T09:44:49+02:00
-->

<template>
  <div id="main">

    <div class="d-flex align-items-center">
      <div class="mainTitle">
        <div id="mtitle">Users</div>
        <div id="stitle">List of users</div>
        <hr>
      </div>
      <b-button class="largeColoredButton text-nowrap ml-auto mr-1" style="font-size:0.8em;" @click="goToAdmin()">ADMINS ACCOUNTS</b-button>
      <b-button class="largeColoredButton text-nowrap mx-1" style="font-size:0.8em;" @click="getCSV()">GET CSV</b-button>
    </div>

    <b-table :items="filteredData" :fields="fields" :current-page="currentTablePage" :per-page="10">
      <template slot="top-row" slot-scope="{ fields }">
        <td class="px-1 py-1" v-for="field in fields" :key="field.key">
          <b-input class="w-100 m-0 py-0 pl-1 pr-2" v-if="['username','email'].includes(field.key)" v-model="filters[field.key]" :placeholder="field.label" />
        </td>
      </template>
      <template #head(reset_password)="data">
        <div class="d-flex align-items-center">
          <span class="mr-1">Has Create/Reset password</span>
          <img v-b-tooltip.hover.bottom="{ variant: 'info' }" title="Indicates whether the user has successfully initialized their password or reset it on a subsequent change. Warning: If a new reset link is generated but not used, the value displayed will be 'NO'!" src="@/assets/img/png/ico_info.png" class="m-0 p-0" style="width:0.8em;height:0.8em;opacity:0.5;"/>
        </div>
      </template>
      <template v-slot:cell(username)="data">
        <span :class="{'largeTitle': data.item.activated }">{{ data.item.username }}</span>
      </template>
      <template v-slot:cell(email)="data">
        <span class="largeTitle">{{ data.item.data.email }}</span>
      </template>
      <template v-slot:cell(created_at)="data">
        {{ toStringDate(data.item.created_at) }}
      </template>
      <template v-slot:cell(plan_expire_on)="data">
        {{ hasPremium(data.item.plan_expire_on) }}
      </template>
      <template v-slot:cell(reset_password)="data">
        {{ (data.item.has_reset_password)? 'YES' : 'NO' }}
      </template>
      <template v-slot:cell(actions)="data">
        <!-- <div class="d-flex">
          <b-dropdown class="myDropdown my-auto ml-auto" dropleft no-caret>
            <template #button-content>
              <img src="@/assets/img/svg/ico_threedots.svg" style="height:1em;">
            </template>
            <b-dropdown-item-button class="largeTitle"  @click="current_user_id = data.item._id;showUserCard = true">Display</b-dropdown-item-button>
            <b-dropdown-item-button class="largeTitle"  @click="current_user_id = data.item._id;showUserPlan = true">Plan</b-dropdown-item-button>
            <b-dropdown-item-button class="largeTitle"  @click="current_user_id = data.item._id;showUserStats = true">Stats</b-dropdown-item-button>
          </b-dropdown>
        </div> -->
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center ml-auto" @click="current_user_id = data.item._id;showUserCard = true" title="Display" style="cursor:pointer;">
            <img class="mr-1" src="@/assets/img/png/ico_user.png" style="height:1.3em;">
          </div>
          <div class="d-flex align-items-center" @click="current_user_id = data.item._id;showUserPlan = true" title="Plan" style="cursor:pointer;">
            <img class="mr-1" src="@/assets/img/png/ico_plan.png" style="height:1.3em;">
          </div>
          <div class="d-flex align-items-center" @click="current_user_id = data.item._id;showUserStats = true" title="Stats" style="cursor:pointer;">
            <img class="mr-1" src="@/assets/img/png/ico_stats.png" style="height:1.3em;">
          </div>
          <div class="d-flex align-items-center" @click="current_user_id = data.item._id;showUserLoginHistory = true" title="Login History" style="cursor:pointer;">
            <img v-if="!data.item.never_connected" class="mr-1" src="@/assets/img/png/ico_login.png" style="height:1.3em;">
            <img v-else class="mr-1" src="@/assets/img/png/ico_login_not_activated.png" title="Never connected" style="height:1.3em;">
          </div>
        </div>
      </template>
    </b-table>


    <hr>

    <div class="d-flex align-items-center">
      <b-pagination v-if="filteredData.length > 10" class="p-0 my-2 ml-2 mr-auto" :total-rows="filteredData.length" :per-page="10" v-model="currentTablePage"/>
      <b-button class="largeColoredButton mr-2 ml-auto" style="font-size:0.8em;" @click="current_user_id = null;showUserCard = true">CREATE A NEW USER</b-button>
    </div>

    <div class="d-flex mb-4">
    </div>

    <b-modal size="xl" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showUserCard">
      <b-button class="crossButton" @click="current_user_id = null;showUserCard = false">✕</b-button>
      <userCard :id="current_user_id" :userData="userData" @created="userCreated($event)" @updated="userUpdated($event)" @close="current_user_id = null;showUserCard = false"></userCard>
    </b-modal>

    <b-modal size="xl" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showUserPlan">
      <b-button class="crossButton" @click="current_user_id = null;showUserPlan = false">✕</b-button>
      <planCard :id="current_user_id" @close="current_user_id = null;showUserPlan = false"></planCard>
    </b-modal>

    <b-modal size="xl" hide-header hide-footer v-model="showUserStats">
      <b-button class="crossButton" @click="current_user_id = null;showUserStats = false">✕</b-button>
      <statsCard :id="current_user_id" @close="current_user_id = null;showUserStats = false"></statsCard>
    </b-modal>

    <b-modal size="xl" hide-header hide-footer v-model="showUserLoginHistory">
      <b-button class="crossButton" @click="current_user_id = null;showUserLoginHistory = false">✕</b-button>
      <connectionsHistoryCard :id="current_user_id" @close="current_user_id = null;showUserLoginHistory = false"></connectionsHistoryCard>
    </b-modal>

  </div>
</template>

<script>

////////////////// Import //////////////////

import userCard from './user'
import planCard from './plan'
import statsCard from './stats'
import connectionsHistoryCard from './connectionsHistory'

import Users from '@/services/users'
const usersService = new Users();

import FileSaver from 'file-saver'

////////////////// Functions //////////////////

function getUsersList(self, callback){
   usersService.getList().then(function(result) {
    self.users = result.body.users;
    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
    callback();
  });
}

export default {
  name: 'Home',
  data () {
    return {
      loading: true,
      showUserCard: false,
      showUserPlan: false,
      showUserStats: false,
      showUserLoginHistory: false,
      fields: [],
      users: [],
      current_user_id: null,
      userData: null,
      currentTablePage: 1,
      filters: {
        username: '',
        email: ''
      },
    }
  },
  components: {
    userCard,
    planCard,
    statsCard,
    connectionsHistoryCard
  },
  computed: {
    filteredData () {
      const filtered = this.users?.filter(item => {
        return Object.keys(this.filters).every(key => {
          if(!this.filters[key] || this.filters[key] == '') return true;
          if(key == 'email'){
            return String(item.data.email).toUpperCase().includes(this.filters['email'].toUpperCase())
          } else {
            return String(item[key]).toUpperCase().includes(this.filters[key].toUpperCase())
          }
        })
      })
      return (filtered && filtered.length > 0) ? filtered : []
    }
  },
  created: function() {
    var self = this;

    this.fields = [
      { key: 'username', label: 'Username', sortable: true },
      { key: 'email', label: 'Email', sortable: true },
      { key: 'plan_expire_on', label: 'Has premium Access', sortable: true },
      { key: 'reset_password', label: '', sortable: true },
      { key: 'created_at', label: 'Created at', sortable: true },
      { key: 'actions', label: '' }
    ]

    // data in the query
    if(this.$route.query.data) {
      let data = JSON.parse(this.$route.query.data);
      // Init new user with data
      if(data.type == 'signup_request'){
        this.current_user_id = null;
        this.userData = data;
        this.showUserCard = true;

        // Remove query after get data
        let path = this.$route.path;
        window.history.replaceState({}, document.title, '' + this.$route.path);

      }
    }

    getUsersList(self, function () {
      self.loading = false;
    });
  },
  methods: {
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR') + "\n" + (new Date(date)).toLocaleTimeString('fr-FR');
    },
    hasPremium(date) {
      if(date == null) return "NO";
      return (new Date(date).getTime() > Date.now())? 'YES':'NO';
      // let days = (new Date(date).getTime() - Date.now()) / 86400000;
      // return ((days > 0)? 'YES' : 'NO')  + ' ' + ((days > 0)? '('  + parseInt(days) + ' days left)': '');
    },
    goToAdmin(){
      this.$router.push({ name: 'admins' })
    },
    userCreated(data){
      this.users.push(data.user);
    },
    userUpdated(data){
      let index = this.users.findIndex(a => a._id == data.user._id);
      if(index > -1) this.$set(this.users, index, data.user)
    },
    getCSV() {
      let self = this;
      usersService.getCSV().then(function(result) {
        let csv = result.body.csv;
        var file = new Blob([csv], {type: "text/csv"});
        FileSaver.saveAs(file, "users.csv");
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';


</style>
