<!--
@Author: laborde
@Date:   2022-03-23T09:33:58+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-04-14T10:38:14+02:00
-->

<template>
  <div>
    <topmenu v-if="isauthenticated()"></topmenu>
    <router-view/>
  </div>
</template>

<script>

console.log("Load admin.vue");

/////////////////// import ///////////////////

import topmenu from './components/topmenu'

/////////////////// Functions ///////////////////


/////////////////// Vue ///////////////////

export default {
  name: 'mainview',
  data () {
    return {

    }
  },
  components: {
    topmenu
  },
  computed: {
  },
  methods: {
    isauthenticated() {
      var access_token_sba = this.$cookies.get('access_token_sba');
      return (access_token_sba != undefined)
    }
  }
}


</script>

<style>

</style>
