/**
 * @Author: laborde
 * @Date:   2018-07-10T10:47:15+02:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-06-16T13:31:54+02:00
 */

import Vue from 'vue'

export default function RedirectIfAuthenticated (router) {
   /**
    * If the user is already authenticated he shouldn't be able to visit
    * pages like login, etc...
    */
    router.beforeEach((to, from, next) => {
      var authRequired = to.matched.some(record => record.meta.requiresAuth);

      var access_token_sba = window.$cookies.get('access_token_sba');
      if (access_token_sba == undefined && to.name !== "login" && authRequired) {
        console.log("Redirect to login");
        return next('/app/login?expired=true&to='+ to.path + '&query='+ JSON.stringify(to.query));
      } else if (access_token_sba != undefined && to.name === "login" && !authRequired) {
        console.log("Redirect to app");
        return next('/app');
      }

      next();
   })
}
