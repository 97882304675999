/**
 * @Author: laborde
 * @Date:   2022-03-23T10:01:49+01:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-04-29T13:49:57+02:00
 */

'use strict'

import Vue from 'vue'

export default class genericClass {

  getList() {
    return Vue.http.get(Vue.prototype.$_url + "/admins/list");
  }

  get(id) {
    return Vue.http.get(Vue.prototype.$_url + "/admins/retrieve/" + id);
  }

  update(user) {
    return Vue.http.post(Vue.prototype.$_url + "/admins", {user : user});
  }

}
