<!--
@Author: laborde
@Date:   2022-05-17T09:22:23+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-29T10:04:13+02:00
-->

<template>
  <div>

    <!-- {{ current_plan }} -->

    <!-- <h1>NOT FINISHED (DO NOT USE IT) !!!</h1> -->

    <h4 class="largeTitle">Info</h4>

    <p>On this page, you can update/upgrade the premium status of the user.</p>
    <p>An user can have have 2 different status</p>
    <ul>
      <li>Free Trial : The user has, by default, a trial period of 30 days when creating his account.</li>
      <li>Premium : Is the default premium status</li>
    </ul>

    <p>The user has also 3 payment methods</p>
    <ul>
      <li>{{ displayPeymentMethod('stripe_month_subscription') }}</li>
      <li>{{ displayPeymentMethod('external_purchase') }}</li>
      <li>{{ displayPeymentMethod('team_purchase') }}</li>
    </ul>

    <p>You can't modify the data if the user is member of a team or if is subscribe with stripe.</p>

    <hr>

    <h4 class="largeTitle">Data</h4>

    <div v-if="current_plan">

      <div class="d-flex align-items-center mb-2">
        <span class="largeTitle" style="min-width:15%">Current plan </span>
        <span>{{ displayPlan(current_plan.plan) }}</span>
      </div>

      <div class="d-flex align-items-center mb-2">
        <span class="largeTitle" style="min-width:15%">Payment "method" </span>
        <span>{{ displayPeymentMethod(current_plan.payment_method) }}</span>
      </div>

      <div class="d-flex align-items-center mb-2">
        <span class="largeTitle" style="min-width:15%">Expiration date</span>
        <span>{{ toStringDate(current_plan.expire_on) }}</span>
      </div>

      <div v-if="current_team" class="d-flex align-items-center mb-2">
        <span class="largeTitle" style="min-width:15%">Team </span>
        <span>{{ current_team.name }}</span>
      </div>

      <b-button v-if="current_plan.payment_method == 'stripe_month_subscription'" class="largeColoredButton ml-auto" target="_blank" :href="'https://dashboard.stripe.com/test/subscriptions/' + current_plan.stripe.subscription_id">SUBSCRIPTION DETAILS</b-button>
      <b-button v-if="[null,'external_purchase'].includes(current_plan.payment_method)" class="largeColoredButton ml-auto" @click="showUpgrade()">UPGRADE PLAN</b-button>

      <hr>

      <invoicesComp :options="{ origin: true }" :invoices="current_plan.invoices" v-on:invoiceUpdated="invoiceUpdated($event.invoice, $event.index)" v-on:deleteInvoice="deleteInvoice($event.index)"></invoicesComp>

    </div>

    <hr>

    <div class="d-flex mt-5">
      <b-button class="largeColoredButton mr-auto" @click="close()">CANCEL</b-button>
      <b-button class="largeColoredButton ml-auto" @click="updatePlan()">UPDATE PLAN</b-button>
    </div>

    <b-modal size="md" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showUpgradePlan">
      <b-button class="crossButton" @click="showUpgradePlan = false">✕</b-button>

      <div v-if="tmp_plan">

        <div class="mb-3">
          <div class="largeTitle mb-1">Plan</div>
          <b-form-select v-model="tmp_plan.plan" @change="upgradePayment()" :options="plans"></b-form-select>
        </div>

        <div class="mb-3">
          <div class="largeTitle mb-1">Payment Method</div>
          <div>{{ displayPeymentMethod(tmp_plan.payment_method) }}</div>
        </div>

        <div class="mb-2">
          <span class="largeTitle">Expiration date</span>

          <div class="d-flex align-items-center mt-2">
            <b-form-datepicker
            class="w-50 mr-2"
            v-model="tmp_plan.expire_on" locale="fr"
            size="sm"
            start-weekday="1"
            :min="new Date()"
            :disabled="!allowDateManuelUpdate"
            ></b-form-datepicker>
            <div style="color:red" v-if="!tmp_plan.expire_on || !(new Date(tmp_plan.expire_on).getTime() >  Date.now())">
              This date has expired !
            </div>
            <div v-else>
              {{ parseInt((new Date(tmp_plan.expire_on).getTime() - Date.now()) / 86400000)}} days left !
            </div>
          </div>

        </div>

      </div>

      <hr class="mt-4">

      <div class="d-flex" id="buttons">
        <b-button class="largeColoredButton mr-auto" @click="showUpgradePlan = false; tmp_plan = null">CANCEL</b-button>
        <b-button class="largeColoredButton ml-auto" @click="upgradePlan()">UPGRADE</b-button>
      </div>

    </b-modal>

  </div>
</template>

<script>

////////////////// Import //////////////////

import Users from '@/services/users'
const usersService = new Users();

import invoicesComp from '@/components/invoices/invoices'

////////////////// Functions //////////////////

function getPlan(self, callback){
  usersService.getPlan(self.id).then(function(result) {
    self.current_plan = result.body.current_plan;
    self.current_team = result.body.current_team;
    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
    callback();
  });
}

export default {
  name: 'plan',
  props: ['id'],
  data () {
    return {
      loading: true,
      showUpgradePlan: false,
      current_plan: null,
      current_team: null,
      tmp_plan: null
    }
  },
  computed: {
    allowDateManuelUpdate() {
      let payment_method = this.current_plan?.payment_method;
      let team = this.current_plan?.team;
      return !team && [null,'external_purchase'].includes(payment_method);
    },
    fieldsTable() {
      return [
        { key: 'origin', label: "origin", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'name', label: "name", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'type', label: "type", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'number', label: "number", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'amount', label: "amount", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'paid', label: "paid", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'date', label: "date", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'action', label: ""},
      ];
    },
    plans() {
      return [
        { value: 'free_trial', text: this.displayPlan('free_trial') },
        { value: 'premium_starter', text: this.displayPlan('premium_starter') }
      ]
    }
  },
  components: {
    invoicesComp
  },
  created: function() {
    var self = this;
    getPlan(self, function () {
      self.loading = false;
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR') + "\n" + (new Date(date)).toLocaleTimeString('fr-FR');
    },
    displayPlan(plan) {
      switch (plan) {
        case 'free_trial':
          return 'Free trial ('+plan+')'
        case 'premium_starter':
          return 'Premium ('+plan+')'
        default:
          return ''
      }
    },
    displayPeymentMethod(method) {
      switch (method) {
        case 'stripe_month_subscription':
          return 'Subscription with Stripe ('+method+')'
        case 'external_purchase':
          return 'Premium with external (manual) purchase ('+method+')'
        case 'team_purchase':
          return 'Premium through the team ('+method+')'
        default:
          return 'No payment method'
      }
    },
    invoiceUpdated(invoice, index){
      var self = this;
      if(index > -1) {
        self.current_plan.invoices[index] = invoice;
      } else {
        self.current_plan.invoices.push(invoice);
      }
    },
    deleteInvoice(index){
      this.current_plan.invoices.splice(index, 1);
    },
    showUpgrade(){
      this.tmp_plan = {
        plan: this.current_plan.plan,
        payment_method: this.current_plan.payment_method,
        expire_on: this.current_plan.expire_on,
      }
      this.showUpgradePlan = true
    },
    upgradePayment(){
      switch (this.tmp_plan.plan) {
        case 'premium_starter':
          this.tmp_plan.payment_method = 'external_purchase'
          break;
        case 'free_trial':
          this.tmp_plan.payment_method = null;
          break;
      }
    },
    upgradePlan() {
      this.current_plan.plan = this.tmp_plan.plan;
      this.current_plan.payment_method = this.tmp_plan.payment_method;
      this.current_plan.expire_on = this.tmp_plan.expire_on;
      this.showUpgradePlan = false
    },
    updatePlan() {
      var self = this;
      usersService.setPlan(self.id, self.current_plan).then(function(result) {
        self.$bvToast.toast("The plan have been updated !", self.__toaster_opts('success', 'Success'))
        // self.current_plan = result.body.current_plan;
        self.close();
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';


</style>
