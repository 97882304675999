<!--
@Author: laborde
@Date:   2022-04-20T09:17:08+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-07-26T14:59:13+02:00
-->

<template>
  <div>
    <div class="d-flex flex-wrap" v-if="articles">
      <div class="articleContenerClass my-3 mr-4" v-for="(article,i) in articles"  :key="article.uri">
        <articleCard :article="article" :inNewsletter="article.inNewsletter" :mode="mode" :no_image="no_image" v-on:removeArticle="removeArticle(article, i)" v-on:openArticleTo="openArticleTo(article, i)" v-on:removeArticleFromTheme="removeArticleFromTheme(article, i)" v-on:addToNewsletter="addToNewsletter(article, i)"></articleCard>
      </div>
    </div>
  </div>
</template>

<script>

////////////////// Import //////////////////

import articleCard from '@/components/articles/articleCard'

////////////////// Functions //////////////////

export default {
  name: 'articlesGrid',
  props: ['articles','mode','no_image'],
  data () {
    return {

    }
  },
  components: {
    articleCard
  },
  created: function() {

  },
  methods: {
    removeArticle(article, index){
      this.$emit('removeArticle', {article: article, index: index})
    },
    openArticleTo(article, index){
      this.$emit('openArticleTo', {article: article, index: index})
    },
    removeArticleFromTheme(article, index){
      this.$emit('removeArticleFromTheme', {article: article, index: index})
    },
    addToNewsletter(article, index){
      this.$emit('addToNewsletter', {article: article, index: index})
    }
  }
}

</script>

<style lang="scss">

@import '@/assets/css/common.scss';

.articleContenerClass {
  // height: 30em;
  width: 30%;

}


</style>
