/**
 * @Author: laborde
 * @Date:   2022-03-23T09:33:58+01:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-07-27T10:03:25+02:00
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '@/components/login'

import home from '../components/home.vue'
import admins from '../components/users/admins.vue'
import users from '../components/users/users.vue'
import teams from '../components/teams/teams.vue'

import topics from '../components/topics/topics.vue'
import themes from '../components/themes/themes.vue'

import newsletters from '../components/newsletters.vue'

Vue.use(VueRouter)

import middleware from './../middleware/middleware'

var _path = '/app';

const routes = [
  {
    path: _path + '/login',
    name: 'login',
    meta: {
      requiresAuth: false,
      title: 'Login - Smart Beauty Insight Admin'
    },
    component: login,
  },
  {
    path: _path + '/home',
    alias: _path,
    name: 'home',
    component: home,
    meta: {
      requiresAuth: true,
      title: 'Home - Smart Beauty Insight Admin'
    }
  },
  {
    path: _path + '/users',
    name: 'users',
    component: users,
    meta: {
      requiresAuth: true,
      title: 'List of users - Smart Beauty Insight Admin'
    }
  },
  {
    path: _path + '/admins',
    name: 'admins',
    component: admins,
    meta: {
      requiresAuth: true,
      title: 'Admins - Smart Beauty Insight Admin'
    }
  },
  {
    path: _path + '/teams',
    name: 'teams',
    component: teams,
    meta: {
      requiresAuth: true,
      title: 'List of teams - Smart Beauty Insight Admin'
    }
  },
  {
    path: _path + '/topics',
    name: 'topics',
    component: topics,
    meta: {
      requiresAuth: true,
      title: 'List of topics - Smart Beauty Insight Admin'
    }
  },
  {
    path: _path + '/themes',
    name: 'themes',
    component: themes,
    meta: {
      requiresAuth: true,
      title: 'List of themes - Smart Beauty Insight Admin'
    }
  },
  {
    path: _path + '/newsletters',
    name: 'newsletters',
    component: newsletters,
    meta: {
      requiresAuth: true,
      title: 'Newsletters - Smart Beauty Insight Admin'
    }
  },
  {
    path: '*',
    redirect: _path
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Home';
  next();
})

// App Middleware
middleware(router);

export default router
