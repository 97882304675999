<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-31T10:14:47+02:00
-->

<template>
  <div id="main">
    <line-loader :loading="loading"></line-loader>

    <div v-if="!loading && topics">

      <div v-if="step == 1" class="d-flex flex-wrap">

        <div class="d-flex align-items-end w-100">
          <div class="mainTitle">
            <div id="mtitle">Topics</div>
            <div id="stitle">{{ topics.length }} topics</div>
            <hr>
          </div>
          <div class="d-flex align-items-center ml-auto mr-1 mb-2">
            <b-form-select style="width:15em;" v-model="filter_by" :options="filterList"></b-form-select>
          </div>
        </div>

        <div class="topicTileClass" v-for="(topic, i) in filteredTopics"  :key="topic.uri">
          <div ref="topic"></div>
          <div class="d-flex flex-column mx-auto" @click="goToTopic(topic, i)">
            <img crossorigin="anonymous" class="mx-auto mb-3" :src="'https://eventregistry.org' + topic.image_url" style="width:5em;height:5em;">
            <span class="mx-auto" id="title">{{ topic.name }}</span>
            <span class="mx-auto" id="subtitle">{{ topic.articlesCount }} articles</span>
            <span class="mx-auto" id="subtitle">{{ topic.newArticlesCount }} new articles</span>
          </div>

        </div>

      </div>

      <div v-if="step == 2 && current_topic">
        <list :topic="current_topic" :themes="themes"  @goBack="goToTopicsDisplay()"></list>
      </div>




    </div>



  </div>
</template>

<script>

////////////////// Import //////////////////

import list from './articles'

import Topics from '@/services/topics'
const topicsService = new Topics();

import Themes from '@/services/themes'
const themesService = new Themes();

////////////////// Functions //////////////////

function getTopicsList(self, callback){

  topicsService.getList().then(function(result) {
    self.topics = result.body.topics;

    self.topics.sort((a, b) => a.name.localeCompare(b.name))

    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
    callback();
  });
}

function getThemesList(self, callback){
  themesService.getList().then(function(result) {
    let themes = result.body.themes;

    // themes = themes.map(a => {
    //   return { value: a._id, text: a.name_en }
    // })
    // themes.unshift({ value: null, text: 'Select a theme' });

    self.themes = themes;

    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    callback();
  });
}


export default {
  name: 'Home',
  data () {
    return {
      loading: true,
      topics: null,
      themes: null,
      step: 1,
      current_topic: null,
      current_topic_index: null,
      filterList: [],
      filter_by: null,
    }
  },
  components: {
    list
  },
  computed: {
    filteredTopics: function() {
      console.log("this.filter_by = " + this.filter_by);
      if(this.filter_by && this.filter_by == 'news') {
        return this.topics.filter(a => a.newArticlesCount > 0);
      } else {
        return this.topics;
      }
    },
  },
  created: function() {
    var self = this;

    this.filterList = [
      { value: null, text: "All topics"},
      { value: 'news', text: "Topics with new articles"}
    ]

    getTopicsList(self, function () {
      getThemesList(self, function () {
        self.loading = false;
      });
    });
  },
  methods: {
    goToTopicsDisplay(){
      this.step = 1;
      this.current_topic = null;
      this.$nextTick(
        function () {
          this.scrollToTopic(this.current_topic_index);
          this.current_topic_index = null;
        }.bind(this)
      );
    },
    goToTopic(topic, index){
      this.current_topic = topic;
      this.current_topic_index = index;
      this.step = 2;
    },
    scrollToTopic(index) {
      var element = this.$refs.topic[index];
      element.scrollIntoView({behavior: 'instant'});
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';



</style>
