<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-04-29T15:09:11+02:00
-->

<template>
  <div id="main">
    <p>Welcome to the Smart Beauty Insight administration interface</p>

  </div>
</template>

<script>

////////////////// Import //////////////////

////////////////// Functions //////////////////

export default {
  name: 'Home',
  data () {
    return {

    }
  },
  created: function() {

  },
  methods: {

  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';


</style>
