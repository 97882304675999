/**
 * @Author: laborde
 * @Date:   2022-03-23T10:01:49+01:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-07-27T13:45:47+02:00
 */

'use strict'

import Vue from 'vue'

export default class genericClass {
  getListFromTopic(topic_uri) {
    return Vue.http.get(Vue.prototype.$_url + "/articles/list?topic_uri="+ topic_uri);
  }

  getListFromTheme(theme_id) {
    return Vue.http.get(Vue.prototype.$_url + "/articles/list?theme_id="+ theme_id);
  }

  getListFromURIs(uris) {
    return Vue.http.get(Vue.prototype.$_url + "/articles/list?uris="+ JSON.stringify(uris));
  }


  remove(uri) {
    return Vue.http.delete(Vue.prototype.$_url + "/articles/"+ uri);
  }

  addToThemes(articles_uri, topic_uri, themes_ids, overwrite = false) {
    return Vue.http.post(Vue.prototype.$_url + "/articles/add/themes", { articles_uri: articles_uri, topic_uri: topic_uri, themes_ids: themes_ids, overwrite: overwrite});
  }

  removeToTheme(article_uri, theme_id) {
    return Vue.http.post(Vue.prototype.$_url + "/articles/remove/theme", { article_uri: article_uri, theme_id: theme_id});
  }

}
