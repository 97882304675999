<!--
@Author: laborde
@Date:   2022-04-13T13:14:50+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-09-30T09:39:37+02:00
-->

<template>
  <div>

     <!-- admin = {{ admin }} -->

    <div v-if="admin">

      <h4 class="largeTitle mb-3">{{ admin.username }}</h4>

      <div class="mb-3">Access Level : <span class="largeTitle">{{ admin.access_level || 'N1'}}</span></div>

      <hr>

      <div class="mb-3">
        <div class="mb-1">Email (use for Two factor auth)</div>
        <b-input class="w-50"
          v-model.trim="admin.data.email"
          type="email"
          placeholder="email">
        </b-input>
      </div>

      <hr>


      <div class="d-flex align-items-center mb-3">
        <span class="mr-2">Activated ?</span>
        <b-form-checkbox v-model="admin.activated" switch></b-form-checkbox>
      </div>

      <div v-if="admin.created_at" class="mb-3">Created at {{ toStringDate(admin.created_at) }}</div>

    </div>

    <hr>

    <div class="d-flex">
      <b-button class="largeColoredButton mr-auto" @click="close()">CANCEL</b-button>
      <b-button class="largeColoredButton ml-auto" @click="update()">UPDATE</b-button>
    </div>

  </div>
</template>

<script>

////////////////// Import //////////////////

import Admins from '@/services/admins'
const adminsService = new Admins();

////////////////// Functions //////////////////

function getAdmin(self, callback){
  adminsService.get(self.id).then(function(result) {
    self.admin = result.body.admin;
    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
    callback();
  });
}

export default {
  name: 'Home',
  props: ['id'],
  data () {
    return {
      loading: true,
      admin: null
    }
  },
  created: function() {
    var self = this;
    getAdmin(self, function () {
      self.loading = false;
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR') + "\n" + (new Date(date)).toLocaleTimeString('fr-FR');
    },
    update() {
      var self = this;
      adminsService.update(self.admin).then(function(result) {
        self.$emit('updated', { admin: self.admin });
        self.close();
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';


</style>
