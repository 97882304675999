<!--
@Author: laborde
@Date:   2022-05-17T09:22:23+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-09-22T10:23:13+02:00
-->

<template>
  <div>


    <div class="d-flex align-items-center mt-5">
      <h4 class="largeTitle">Invoices</h4>
      <b-button class="largeColoredButton ml-auto" @click="setNewInvoice()">NEW INVOICE</b-button>
    </div>

    <b-table class="mt-3" :items="invoices" :fields="fieldsTable" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
      <template v-slot:cell(name)="data">
       {{ data.item.name }}
      </template>
      <template v-slot:cell(type)="data">
        {{ data.item.type }}
      </template>
      <template v-slot:cell(number)="data">
        {{ data.item.number }}
      </template>
      <template v-slot:cell(date)="data">
        {{ toStringDate(data.item.date) }}
      </template>
      <template v-slot:cell(action)="data">
        <div class="d-flex">
          <b-dropdown class="myDropdown ml-auto" dropleft no-caret>
            <template #button-content>
              <img src="@/assets/img/svg/ico_threedots.svg" style="height:1em;">
            </template>
            <b-dropdown-item-button class="largeTitle"  @click="updateInvoice(data.item)">Update</b-dropdown-item-button>
            <b-dropdown-item-button class="largeTitle"  @click="deleteInvoice(data.index)">Delete</b-dropdown-item-button>
          </b-dropdown>
        </div>
      </template>
    </b-table>

    <b-modal size="md" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showInvoice">
      <b-button class="crossButton" @click="showInvoice = false">✕</b-button>

      <h4 class="largeTitle">Invoice</h4>

      <div v-if="current_invoice">

        <div v-if="options.origin" class="mb-3">
          <div>Origin : {{ current_invoice.origin }}</div>
        </div>

        <div class="mb-3">
          <div class="mb-1">Type</div>
          <b-form-select v-model="current_invoice.type" :options="['invoice','proforma_invoice']"></b-form-select>
        </div>

        <div class="mb-3">
          <div class="mb-1">Name/Description</div>
          <b-input v-model="current_invoice.name" type="text" placeholder="Name or description" />
        </div>

        <div class="mb-3">
          <div class="mb-1">Invoice number</div>
          <b-input v-model="current_invoice.number" type="text" placeholder="Number" />
        </div>

        <div class="mb-3">
          <div class="mb-1">Amout (in cents: 10€ => 1000)</div>
          <div class="d-flex align-items-center">
            <b-input class="mr-1" v-model="current_invoice.amount" min="0" type="number" placeholder="1000"/>
            <span>€</span>
          </div>

        </div>

        <div class="mb-3">
          <div class="mb-1">Paid</div>
          <div class="d-flex">
            <span class="mr-1">No</span>
            <b-form-checkbox v-model="current_invoice.paid" switch></b-form-checkbox>
            <span class="ml-1">Yes</span>
          </div>
        </div>

        <div class="mb-3">
          <div class="mb-1">Date</div>
          <b-form-datepicker
          v-model="current_invoice.date" locale="fr"
          size="sm"
          start-weekday="1"
          ></b-form-datepicker>
        </div>

      </div>

      <hr>

      <div class="d-flex justify-content-between">
        <b-button class="largeColoredButton" @click="showInvoice = false ; current_invoice = null">CLOSE</b-button>
        <b-button class="largeColoredButton" @click="updateInvoices()">ADD/UPDATE</b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>

////////////////// Import //////////////////

////////////////// Functions //////////////////

export default {
  name: 'plan',
  props: ['invoices', 'options'],
  data () {
    return {
      showInvoice: false,
      current_invoice: null,
      sortBy: 'date',
      sortDesc: true
    }
  },
  computed: {
    fieldsTable() {
      let list = [
        { key: 'name', label: "name", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'type', label: "type", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'number', label: "number", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'amount', label: "amount", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'paid', label: "paid", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'date', label: "date", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'action', label: ""},
      ];
      if(this.options.origin) list.unshift({ key: 'origin', label: "origin", sortable: true, thClass: 'listTableTitleClass'});
      return list;
    },
  },
  created: function() {
    var self = this;
  },
  methods: {
    close() {
      this.$emit('close');
    },
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR') + "\n" + (new Date(date)).toLocaleTimeString('fr-FR');
    },
    setNewInvoice(){
      this.current_invoice = {
        type: 'invoice',
        name: '',
        number: '',
        amount: null,
        paid: false,
        date: new Date(),
        __isNew: true
      };
      if(this.options.origin) this.current_invoice.origin = 'custom';
      this.showInvoice = true
    },
    updateInvoices(){
      var self = this;

      console.log("self.current_invoice = " + JSON.stringify(self.current_invoice));

      let index = this.invoices.findIndex(a => a._id == self.current_invoice._id);

      self.$emit('invoiceUpdated', { invoice: self.current_invoice, index: index})

      self.current_invoice = null;
      self.showInvoice = false
    },
    updateInvoice(invoice){
      this.current_invoice = invoice;
      this.showInvoice = true;
    },
    deleteInvoice(index){
      this.$emit('deleteInvoice', { index: index})
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';


</style>
