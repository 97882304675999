<!--
@Author: laborde
@Date:   2022-04-11T10:08:20+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-10-05T09:45:26+02:00
-->

<template>
  <div>
    <div id="conteneur" class="d-flex flex-column">

      <div class="mt-2 mb-3 mx-auto" style="position:relative">
        <img class="mt-4" src="@/assets/img/png/logo.png" style="height:6em">
        <span class="largeTitle" style="position:absolute;top:2em;right:-2em">Admin</span>
      </div>

      <div class="cont mx-auto mb-auto">
        <b-form @submit.prevent="login()">
          <b-input class="myInput mb-2" type="text" v-model="auth.username" required placeholder="Username"></b-input>
          <b-input class="myInput mb-2" type="password" v-model="auth.password" required placeholder="Password"></b-input>

          <div class="d-flex align-items-center mb-2">
            <b-form-checkbox class="mr-1" v-model="trustDevice">Trust this device</b-form-checkbox>
            <img v-b-tooltip.hover.righttop="{ variant: 'info' }" title="Perform two-factor authentication only once a week." src="@/assets/img/png/ico_info.png" class="m-0 p-0" style="width:0.8em;height:0.8em;opacity:0.5;"/>
          </div>

          <b-button id="login_button" class="large" type="submit"><span>LOG IN</span></b-button>
        </b-form>
      </div>
      <!-- <div align="center" class="mx-auto mt-3" style="color:red">Alpha version</div> -->
    </div>

    <b-modal size="md" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showCode">
      <b-button class="crossButton" @click="closeCode()">✕</b-button>

      <h3>Two-factor authentication</h3>

      <div class="mb-2">
        <span class="mr-2">Email </span>
        <span class="largeTitle">{{ email }}</span>
      </div>

      <b-button v-if="twoFactoreStep == 1" class="largeColoredButton w-100 mx-auto mt-3" @click="sendCode()">Send code</b-button>

      <div v-if="twoFactoreStep == 2">
        <b-input autocomplete="one-time-code" class="myInput mb-2" type="text" v-model="auth.code" required placeholder="Code"></b-input>
        <div class="d-flex flex-column mt-3">
          <b-button id="login_button" class="large w-100 mx-auto" @click="login()">SEND CODE</b-button>
          <hr class="w-100">
          <b-button class="largeColoredButton w-100 mx-auto" @click="sendCode()">RESENT CODE</b-button>
        </div>
      </div>


    </b-modal>

  </div>
</template>

<script>

/////////////////// Vue ///////////////////

export default {
  data () {
    return {
      showCode: false,
      twoFactoreStep: 1,
      auth: {
        username: '',
        password: null,
        code: null
      },
      user: {},
      trustDevice: false,
      trustedDevice: null,
      email: null
    }
  },
  created: function () {
    this.trustedDevice = this.$cookies.get('trusted_device_sba') || null;
    if(this.trustedDevice && this.trustedDevice != '') this.trustDevice = true;

    if(this.$route.query.expired == 'true') this.$bvToast.toast('Your session has expired. Please log-in again', this.__toaster_opts('error', 'Error'))
  },
  methods: {
    sendCode () {
      let self = this;
      if(!self.auth.username || self.auth.username == '')  return self.$bvToast.toast("You must enter your username", self.__toaster_opts('error', 'Error'));
      if(!self.auth.password || self.auth.password == '')  return self.$bvToast.toast("You must enter your password", self.__toaster_opts('error', 'Error'));

      self.$http.post(self.$_url + '/auth/login/sendCode', self.auth).then((response) => {
        let status = response.data.status;
        self.$bvToast.toast('Email Sent !', self.__toaster_opts('success', 'Success'));
        self.twoFactoreStep = 2;
        self.showCode = true;
      }).catch((errors) => {
        self.$bvToast.toast(errors.body.message, self.__toaster_opts('error', 'Error'));
      })
    },
    closeCode(){
      this.showCode = false;
      this.twoFactoreStep = 1;
      this.auth.password = null;
      this.auth.code = null;
      this.email = null;
    },
    login () {
      let self = this

      if(!self.auth.username || self.auth.username == '')  return self.$bvToast.toast("You must enter your username", self.__toaster_opts('error', 'Error'));
      if(!self.auth.password || self.auth.password == '')  return self.$bvToast.toast("You must enter your password", self.__toaster_opts('error', 'Error'));


      if(self.trustDevice) {
        if(self.trustedDevice) self.auth.trusted_device = self.trustedDevice;
        else self.auth.trust_device = true;
      } else {
        // Remove trusted device
        self.$cookies.remove('trusted_device_sba');
        self.trustedDevice = null;
      }

      this.$http.post(this.$_url + '/auth/login', this.auth).then((response) => {

        if(response.data.status == 'requets_code'){
          // Display popup to get secret code
          self.email = response.data.email;
          self.showCode = true;
          return;
        }

        this.$cookies.set('access_token_sba', response.data.access_token, 60*60*24);

        // Add trusted device
        if(response.data.trusted_device && response.data.trusted_device != ''){
          self.$cookies.set('trusted_device_sba', response.data.trusted_device, '7d');
          self.trustedDevice = response.data.trusted_device;
        }

        if(this.$route.query.to) {
          let url = this.$route.query.to;
          if(this.$route.query.query && this.$route.query != ''){
            let query = JSON.parse(this.$route.query.query);
            let keys = Object.keys(query);
            if(keys && keys.length > 0){
              let m = keys.map(a => a + '=' + query[a]).join('&');
              url = url + '?' + m
            }
          }
          this.$router.push(url);
        } else {
          this.$router.push('/app');
        }
      }).catch((errors) => {
        this.$bvToast.toast(JSON.stringify(errors.body.message), this.__toaster_opts('error', 'Error'))
        // catch errors
      })
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/css/common.scss';

#conteneur {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  justify-content: center;
  background-color: #f3f3f3;
}

.cont {
  width: 30%;
  background-color: Transparent;
}

#login_button {
  border:none;
  border-radius: 0;
  background-color:$color-1;
  font-size: 1.2em;
  color: white;
  font-family: 'Raleway-Bold';
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.myInput {
  border-radius: 0;
  border-color: #dadada;
}

.myInput::placeholder {
  color: #999999;
}

.large {
  width: 100%;
}

</style>
