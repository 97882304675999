<!--
@Author: laborde
@Date:   2022-04-13T13:14:50+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-06-08T10:16:04+02:00
-->

<template>
  <div id="main">

    <div class="mainTitle">
      <div id="mtitle">Teams</div>
      <div id="stitle">List of teams</div>
      <hr>
    </div>

    <b-table :items="teams" :fields="fields">
      <template v-slot:cell(name)="data">
        <span class="largeTitle">{{ data.item.name }}</span>
      </template>
      <template v-slot:cell(created_at)="data">
        {{ toStringDate(data.item.created_at) }}
      </template>
      <template v-slot:cell(expire_on)="data">
        {{ toStringDate(data.item.plan.expire_on) }}
      </template>
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-column">
          <b-button class="largeColoredButton ml-auto mb-2" @click="current_team_id = data.item._id;showTeamCard = true">DISPLAY</b-button>
        </div>
      </template>
    </b-table>


    <div class="d-flex">
      <b-button class="largeColoredButton mx-auto" @click="current_team_id = null;showTeamCard = true">CREATE</b-button>
    </div>

    <b-modal size="xl" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showTeamCard">
      <b-button class="crossButton" @click="current_team_id = null;showTeamCard = false">✕</b-button>
      <teamCard :id="current_team_id" :users="users" @teamCreated="teamCreated($event)" @teamUpdated="teamUpdated($event)" @close="current_team_id = null;showTeamCard = false"></teamCard>
    </b-modal>

  </div>
</template>

<script>

////////////////// Import //////////////////

import teamCard from './team'

import Teams from '@/services/teams'
const teamsService = new Teams();

import Users from '@/services/users'
const usersService = new Users();

////////////////// Functions //////////////////

function getTeamsList(self, callback){
   teamsService.getList().then(function(result) {
    self.teams = result.body.teams;

    usersService.getUsernames().then(function(result) {
      self.users = result.body.users;
      callback();
    }, function(err) {
      console.error("err = " + JSON.stringify(err));
      self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      callback();
    });
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
    callback();
  });
}

export default {
  name: 'Teams',
  data () {
    return {
      loading: true,
      showTeamCard: false,
      fields: [],
      teams: null,
      users: null,
      current_team_id: null
    }
  },
  components: {
    teamCard
  },
  created: function() {
    var self = this;

    this.fields = [
      { key: 'name', label: 'Team Name', sortable: true },
      { key: 'expire_on', label: 'Expire on', sortable: true },
      { key: 'created_at', label: 'Created at', sortable: true },
      { key: 'actions', label: '' }
    ]

    getTeamsList(self, function () {
      self.loading = false;
    });
  },
  methods: {
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR') + "\n" + (new Date(date)).toLocaleTimeString('fr-FR');
    },
    teamCreated(data){
      this.teams.push(data.team);
    },
    teamUpdated(data){
      let index = this.teams.findIndex(a => a._id == data.team._id);
      if(index > -1) this.$set(this.teams, index, data.team)
    },
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';


</style>
