<!--
@Author: laborde
@Date:   2022-04-13T13:14:50+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-29T09:38:04+02:00
-->

<template>
  <div>

    <!-- {{ user }} -->


    <div v-if="user">
      <div class="mb-3">
        <div class="mb-1">Username</div>
        <b-form-input class="w-50"
          id="inputLive"
          v-model="user.username"
          type="text"
          :state="(!user.username || user.username.length >= 8)? null : false"
          aria-describedby="inputLiveFeedback"
          placeholder="username">
        </b-form-input>
        <b-form-invalid-feedback id="inputLiveFeedback">
          Enter at least 8 letters
        </b-form-invalid-feedback>
      </div>

      <h5 class="largeTitle">Information</h5>

      <div class="mb-3">
        <div class="mb-1">Email</div>
        <b-input class="w-50"
          v-model.trim="user.data.email"
          type="email"
          placeholder="email">
        </b-input>
      </div>

      <div class="mb-3">
        <div class="mb-1">Name</div>
        <b-input class="w-50" v-model="user.data.name" type="text" placeholder="name" />
      </div>
      <div class="mb-3">
        <div class="mb-1">Company</div>
        <b-input class="w-50" v-model="user.data.company" type="text" placeholder="company" />
      </div>
      <div class="mb-3">
        <div class="mb-1">Emails language</div>
        <b-form-select class="w-50" v-model="user.data.language" :options="['en','fr']" />
      </div>

      <h5 class="largeTitle">Settings</h5>

      <p>The user can change these settings from the "Account" tab of the application.</p>

      <div class="d-flex mb-2">
        <div class="mr-2">Send weekly newsletter ?</div>
        <span class="mr-1 color1 font-weight-bold">{{ (user.settings.newsletters.weekly)? 'YES' : 'NO'}}</span>
        <!-- <span class="mr-1">No</span>
        <b-form-checkbox v-model="user.settings.newsletters.weekly" switch></b-form-checkbox>
        <span>Yes</span> -->
      </div>

      <hr>

      <div class="d-flex align-items-center mb-3">
        <span class="mr-2">Activated ?</span>
        <b-form-checkbox v-model="user.activated" switch></b-form-checkbox>
      </div>


      <div v-if="user.created_at" class="mb-3">Created at {{ toStringDate(user.created_at) }}</div>

    </div>

    <div v-if="!create_mode" class="mt-3">
      <h5 class="largeTitle">Actions</h5>
      <div class="d-flex">
        <b-button class="largeColoredButton" @click="resetPassword()">GET RESET PASSWORD LINK</b-button>
      </div>

    </div>



    <hr>

    <div class="d-flex">
      <b-button class="largeColoredButton mr-auto" @click="close()">CANCEL</b-button>
      <b-button v-if="!create_mode" class="largeColoredButton ml-auto" @click="update()">UPDATE</b-button>
      <b-button v-if="create_mode" class="largeColoredButton ml-auto" @click="create()">CREATE</b-button>
    </div>

    <b-modal size="xl" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showCreatedUser">
      <b-button class="crossButton" @click="showUserCard = false">✕</b-button>

      <h4 class="largeTitle">User created !</h4>

      <div v-if="reset_link" class="mb-3">
        <h6 class="largeTitle">Link :</h6>
        <div>{{ reset_link }}</div>
      </div>

      <h6 class="largeTitle">Langue email</h6>
      <b-select class="w-50" v-model="language" :options="['fr','en']"/>

      <hr>

      <div class="d-flex">
        <b-button class="largeColoredButton mx-auto" @click="close()">CLOSE</b-button>
        <b-button class="largeColoredButton mx-auto" @click="sendEmail(user, language, reset_link)">SEND EMAIL</b-button>
      </div>

    </b-modal>

    <b-modal size="xl" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showResetPassword">
      <b-button class="crossButton" @click="showResetPassword = false">✕</b-button>

      <h4 class="largeTitle">Reset Link !</h4>

      <div>{{ reset_link }}</div>

      <hr>

      <div class="d-flex">
        <b-button class="largeColoredButton mx-auto" @click="showResetPassword = false">CLOSE</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>

////////////////// Import //////////////////

import Users from '@/services/users'
const usersService = new Users();

////////////////// Functions //////////////////

function getUser(self, callback){
  if(!self.id) self.create_mode = true;
  usersService.get(self.id).then(function(result) {
    self.user = result.body.user;
    // Init
    if(self.userData) {
      self.user.username = self.userData.name.toLowerCase().replace(/ /g,'_').replace(/[$&+,:;=?@#|'ʼ<>.^*()%!-]+/g,'');
      self.user.data.email = self.userData.email || self.user.email;
      self.user.data.name = self.userData.name || self.user.name;
      self.user.data.company = self.userData.company || self.user.data.company;
      self.user.data.language = self.userData.language || self.user.data.language;
    }

    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
    callback();
  });
}

function checkUsername(self, username) {
  if(username.length < 8){
    self.$bvToast.toast("Username too short (8 letters min.)", self.__toaster_opts('error', 'Error'))
    return false;
  }
  var unauthorizedChar = ["À","Á","Â","Ã","Ä","Å","Ò","Ó","Ô","Õ","Õ","Ö","Ø","È","É","Ê","Ë","Ç","Ð","Ì","Í","Î","Ï","Ù","Ú","Û","Ü","Ñ","Š","Ÿ","Ž"];
  var chars = username.toUpperCase().split('');
  for (var c of chars) {
    if(unauthorizedChar.includes(c)){
      self.$bvToast.toast("It's forbbiden to use accents in the username !", self.__toaster_opts('error', 'Error'))
      return false;
    }
  }
  return true;
}

export default {
  name: 'Home',
  props: ['id','userData'],
  data () {
    return {
      loading: true,
      showCreatedUser: false,
      showResetPassword: false,
      create_mode: false,
      user: null,
      reset_link: null,
      language: null
    }
  },
  created: function() {
    var self = this;
    getUser(self, function () {
      self.language = self.user.data.language || 'en';
      self.loading = false;
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR') + "\n" + (new Date(date)).toLocaleTimeString('fr-FR');
    },
    create() {

      if(!checkUsername(this, this.user.username)) return;

      var self = this;
      usersService.create(self.user).then(function(result) {
        self.user = result.body.user;
        self.reset_link = result.body.reset_link;
        self.$bvToast.toast("The user have been created !", self.__toaster_opts('success', 'Success'))
        self.showCreatedUser = true;
        self.$emit('created', { user: self.user });
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });
    },
    sendEmail(user, language, reset_link) {
      var self = this;
      self.loading = true;
      let body = {
        id: user._id,
        language: language,
        reset_link: reset_link
      }
      usersService.sendCreationEmail(body).then(function() {
        self.$bvToast.toast('Email sent !', self.__toaster_opts('success', 'Success'))
        self.user._emailsent = true;
        self.loading = false;
        // self.showNewUserResult = false;
        // self.newUserResult = null;
      }, function(err) {
        console.error("Error to send email = " + JSON.stringify(err));
        self.$bvToast.toast("Error to send email", self.__toaster_opts('error', 'Error'))
        self.loading = false;
      });

    },
    update() {
      var self = this;
      usersService.update(self.user).then(function(result) {
        self.$bvToast.toast("The user have been updated !", self.__toaster_opts('success', 'Success'))
        self.user = result.body.user;
        self.$emit('updated', { user: self.user });
        self.close();
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });
    },
    resetPassword() {
      var self = this;
      usersService.resetPassword({id: self.user._id}).then(function(result) {
        // self.$bvToast.toast("The user have been updated !", self.__toaster_opts('success', 'Success'))
        self.reset_link = result.body.reset_link;
        self.showResetPassword = true;
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';


</style>
