<!--
@Author: laborde
@Date:   2022-06-06T13:41:01+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-18T10:24:13+02:00
-->

<template>
  <div>
    <h3 class="largeTitle mb-1">Team</h3>

    <div v-if="team">
      <div class="mb-2">
        <b-form-input class="w-50"
          v-model="team.name"
          type="text"
          placeholder="Name">
        </b-form-input>
      </div>

      <div class="d-flex align-items-center">
        <h5 class="largeTitle mb-0">Users</h5>
        <b-button class="largeColoredButton ml-auto" @click="setNewUser()">NEW USER</b-button>
      </div>

      <b-table class="mt-2" :items="team.users" :fields="fieldsTable">
        <template v-slot:cell(username)="data">
          {{ data.item.username }}
        </template>
        <template v-slot:cell(email)="data">
          {{ data.item.email }}
        </template>
        <template v-slot:cell(is_admin)="data">
          {{ (data.item.is_admin)? 'YES' : 'NO' }}
        </template>
        <template v-slot:cell(created_at)="data">
          {{ toStringDate(data.item.created_at) }}
        </template>
        <template v-slot:cell(updated_at)="data">
          {{ toStringDate(data.item.updated_at) }}
        </template>
        <template v-slot:cell(action)="data">
          <div class="d-flex">
            <b-dropdown class="myDropdown ml-auto" dropleft no-caret>
              <template #button-content>
                <img src="@/assets/img/svg/ico_threedots.svg" style="height:1em;">
              </template>
              <b-dropdown-item-button class="largeTitle"  @click="current_user = data.item; showUser = true">Update</b-dropdown-item-button>
              <b-dropdown-item-button class="largeTitle"  @click="team.users.splice(data.index, 1)">Delete</b-dropdown-item-button>
            </b-dropdown>
          </div>
        </template>
      </b-table>

      <hr>

      <h5 class="largeTitle">Plan</h5>
      <div>This plan is applied to all members of this team.</div>
      <div>If a member has his own premium access, the team plan is override.</div>
      <div class="d-flex align-items-center my-2">
        <span class="mr-2">Expiration date (included)</span>
        <b-form-datepicker
        class="w-25 mr-2"
        v-model="team.plan.expire_on" locale="fr"
        size="sm"
        start-weekday="1"
        :min="new Date()"
        ></b-form-datepicker>
        <div style="color:red" v-if="!team.plan.expire_on || !(new Date(team.plan.expire_on).getTime() >  Date.now())">
          This date has expired. The team lost is premium access !!!
        </div>
        <div v-else>
          {{ parseInt((new Date(team.plan.expire_on).getTime() - Date.now()) / 86400000)}} days left !
        </div>
      </div>

      <hr>

      <invoicesComp :options="{ origin: false }" :invoices="team.plan.invoices" v-on:invoiceUpdated="invoiceUpdated($event.invoice, $event.index)" v-on:deleteInvoice="deleteInvoice($event.index)"></invoicesComp>


    </div>

    <hr>

    <div class="d-flex">
      <b-button class="largeColoredButton mr-auto" @click="close()">CANCEL</b-button>
      <b-button v-if="!create_mode" class="largeColoredButton ml-auto" @click="update()">UPDATE</b-button>
      <b-button v-if="create_mode" class="largeColoredButton ml-auto" @click="create()">CREATE</b-button>
    </div>

    <b-modal size="md" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showUser">
      <b-button class="crossButton" @click="showUser = false">✕</b-button>

      <h4 class="largeTitle">User</h4>

      <div v-if="current_user">

        <!-- current_user = {{ current_user }} -->

        <b-form-select class="mb-3" v-if="current_user.__isNew" v-model="username"  @change="selectUser(username)" :options="usernames"></b-form-select>
        <div v-if="!current_user.__isNew" class="mb-3">
          <div class="largeTitle mb-1">Username</div>
          <span>{{ current_user.username }}</span>
        </div>
        <div v-if="current_user.email" class="mb-3">
          <div class="largeTitle mb-1">Email</div>
          <b-input class="w-50" v-model="current_user.email" type="text" placeholder="Name" />
        </div>

        <div class="mb-3">
          <div class="mb-1">Is Admin ?</div>
          <div class="d-flex">
            <span class="mr-1">No</span>
            <b-form-checkbox v-model="current_user.is_admin" switch></b-form-checkbox>
            <span class="ml-1">Yes</span>
          </div>
        </div>


      </div>

      <div class="d-flex justify-content-between">
        <b-button class="largeColoredButton" @click="showUser = false ; current_user = null">CLOSE</b-button>
        <b-button class="largeColoredButton" @click="updateUsers()">ADD/UPDATE</b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>

////////////////// Import //////////////////

import Teams from '@/services/teams'
const teamsService = new Teams();

import invoicesComp from '@/components/invoices/invoices'

////////////////// Functions //////////////////

function getTeam(self, callback){
  if(!self.id) self.create_mode = true;
  teamsService.get(self.id).then(function(result) {
    self.team = result.body.team;
    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
    callback();
  });
}

export default {
  name: 'Team',
  props: ['id','users'],
  data () {
    return {
      loading: true,
      showUser: false,
      create_mode: false,
      team: null,
      current_user: null,
      username: null
    }
  },
  computed: {
    fieldsTable() {
      return [
        { key: 'username', label: "username", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'email', label: "email", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'is_admin', label: "Is Admin ?", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'created_at', label: "Created At", sortable: true, thClass: 'listTableTitleClass'},
        { key: 'action', label: ""},
      ];
    },
    usernames() {
      let map = this.team.users.map(a => a.username);
      let usernames = this.users.filter(a => !map.includes(a.username)).map(a => {return { value: a, text: a.username }})
      usernames.unshift({ value: null, text: "select an user !"})
      return usernames;
    }
  },
  components: {
    invoicesComp
  },
  created: function() {
    var self = this;
    getTeam(self, function () {
      self.loading = false;
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR') + "\n" + (new Date(date)).toLocaleTimeString('fr-FR');
    },
    setNewUser(){
      this.current_user = {
        username: null,
        email: null,
        is_admin: false,
        created_at: new Date(),
        updated_at: null,
        __isNew: true
      };
      this.showUser = true
    },
    updateUsers(){
      var self = this;

      if(!self.current_user.username || !self.current_user.email){
        self.$bvToast.toast("You must select an user in the list", self.__toaster_opts('error', 'Error'))
        return;
      }
      console.log("self.current_user = " + JSON.stringify(self.current_user));

      let index = this.team.users.findIndex(a => a.id == self.current_user._id);
      if(index > -1) {
        self.team.users[index] = self.current_user;
      } else {
        self.current_user.__isNew = false;
        self.team.users.push(self.current_user);
      }

      console.log("self.team.users = " + JSON.stringify(self.team.users));

      self.current_user = null;
      self.username = null;
      self.showUser = false

    },
    invoiceUpdated(invoice, index){
      var self = this;
      if(index > -1) {
        self.team.plan.invoices[index] = invoice;
      } else {
        self.team.plan.invoices.push(invoice);
      }
    },
    deleteInvoice(index){
      this.team.plan.invoices.splice(index, 1);
    },
    selectUser(data){
      console.log("mk,vfdvdk");
      console.log(JSON.stringify(data));
      this.current_user.id = data._id;
      this.current_user.username = data.username;
      this.current_user.email = data.data?.email;
    },
    create() {
      var self = this;

      console.log("self.team = " + JSON.stringify(self.team));

      if(self.team.name.length < 5){
        self.$bvToast.toast("Team name too short (5 letters min.)", self.__toaster_opts('error', 'Error'))
        return;
      }

      if(self.team.users.length < 2){
        self.$bvToast.toast("A team must have at least 2 members", self.__toaster_opts('error', 'Error'))
        return;
      }

      if(self.team.users.filter(a => a.is_admin).length == 0){
        self.$bvToast.toast("At least one member must by admin", self.__toaster_opts('error', 'Error'))
        return;
      }

      teamsService.create(self.team).then(function(result) {
        self.team = result.body.team;
        self.$bvToast.toast("The team have been created !", self.__toaster_opts('success', 'Success'))
        self.$emit('teamCreated', { team: self.team });
        self.$emit('close');
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });
    },
    update() {
      var self = this;
      teamsService.update(self.team).then(function(result) {
        self.$bvToast.toast("The team have been updated !", self.__toaster_opts('success', 'Success'))
        self.team = result.body.team;
        self.$emit('teamUpdated', { team: self.team });
        self.$emit('close');
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';


</style>
