<!--
@Author: laborde
@Date:   2022-05-17T09:22:23+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-09-16T10:10:25+02:00
-->

<template>
  <div>
    <h3 class="largeTitle">User Connection history</h3>

    <div v-if="!loading && history">

      <hr>
      <div><strong class="largeTitle">Warning</strong> : Logins with an <strong>administrator password</strong> do not appear in the history!</div>
      <hr>
      <div v-for="element in  history" :key="element.ip">

        <h4 class="largeTitle">IP : {{ element.ip }}</h4>

        <h5>Last connection : {{ toStringDate(element.last_connection) }}</h5>

        <b-table :items="element.connections" :fields="fields">
          <template v-slot:cell(last_connection)="data">
            {{ toStringDate(data.item.last_connection) }}
          </template>

        </b-table>


      </div>

    </div>


  </div>
</template>

<script>

////////////////// Import //////////////////

import Users from '@/services/users'
const usersService = new Users();

////////////////// Functions //////////////////

function getHistory(self, callback){
  usersService.getConnectionHistory(self.id).then(function(result) {
    self.history = result.body.history;

    self.history.sort((a,b) => {
      return (a.last_connection <= b.last_connection)? 1 : -1;
    })

    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
    callback();
  });
}

export default {
  name: 'login_history',
  props: ['id'],
  data () {
    return {
      loading: true,
      history: null,
      fields: []
    }
  },
  computed: {

  },
  created: function() {
    var self = this;

    this.fields = [
      { key: 'agent', label: 'Browser', sortable: true },
      { key: 'two_factor_auth_email', label: 'Two factor auth email', sortable: true },
      { key: 'nb_connection', label: 'Nb connections', sortable: true },
      { key: 'last_connection', label: 'Last connection', sortable: true },
    ]

    getHistory(self, function () {
      self.loading = false;
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR') + "\n" + (new Date(date)).toLocaleTimeString('fr-FR');
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';


</style>
