/**
 * @Author: laborde
 * @Date:   2022-03-23T10:01:49+01:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-03-23T10:06:36+01:00
 */

'use strict'

import Vue from 'vue'

export default class genericClass {
  getList() {
    return Vue.http.get(Vue.prototype.$_url + "/topics/list");
  }
}
