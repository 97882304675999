/**
 * @Author: laborde
 * @Date:   2022-03-23T10:01:49+01:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-08-08T14:51:33+02:00
 */

'use strict'

import Vue from 'vue'

export default class  {
  getCurrent() {
    return Vue.http.get(Vue.prototype.$_url + "/newsletters/current");
  }

  getHistory() {
    return Vue.http.get(Vue.prototype.$_url + "/newsletters/history");
  }

  addArticleToCurrent(article_uri) {
    return Vue.http.post(Vue.prototype.$_url + "/newsletters/add", { article_uri: article_uri });
  }

  testNotif() {
    return Vue.http.post(Vue.prototype.$_url + "/newsletters/send");
  }

}
