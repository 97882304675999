/**
 * @Author: laborde
 * @Date:   2022-04-04T14:06:21+02:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-06-24T14:23:03+02:00
 */

'use strict'

import Vue from 'vue'

export default class genericClass {
  getList(details = true) {
    return Vue.http.get(Vue.prototype.$_url + "/themes/list?details=" + details);
  }
}
