/**
 * @Author: laborde
 * @Date:   2022-03-23T09:33:58+01:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-04-26T09:04:26+02:00
 */

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// npm
import Vue from 'vue'
import Resource from 'vue-resource'
import BootstrapVue from 'bootstrap-vue'
import Toasted from 'vue-toasted'
// import IdleVue from 'idle-vue'
import VueCookies from 'vue-cookies'

// Files
import App from './App.vue'
import router from './router'



// Config
Vue.use(Resource);
Vue.use(BootstrapVue);
Vue.use(VueCookies);

Vue.use(Toasted, {
  duration: 3000,
  theme: 'toasted-primary',
  type: 'info',
  position: 'bottom-center',
  keepOnHover: true,
});

// const eventsHub = new Vue()
// Vue.use(IdleVue, {
//   eventEmitter: eventsHub,
//   idleTime: 1000 * 60 * 60
// })

Vue.prototype.__toaster_opts = function (type, title = '', options) {

  let opts = {
    "title": title,
    "toaster": 'b-toaster-bottom-right',
    "variant": 'success',
    "solid": true,
    "appendToast": true,
    "autoHideDelay": 3000
  };

  if(type == 'success'){
    opts.variant = 'success';
  } else {
    opts.variant = 'warning';
  }

  if(options && options.duration) {
    opts['autoHideDelay'] = options.duration;
  }

  return opts;
}

/////////////////// components ///////////////////

import loader from '@/components/loader'
Vue.component('line-loader', loader);



/////////////////// Config ///////////////////
Vue.config.productionTip = false

// API URL
Vue.prototype.$_url = location.origin + "/api/v1";
if(process.env.NODE_ENV == "development"){
  Vue.prototype.$_url = "http://" + location.hostname + ":" + process.env.VUE_APP_PORT_API + "/api/v1";
}

console.log("Vue.prototype.$_url = " + Vue.prototype.$_url);

// Vue

var vm = new Vue({
  router,
  render: h => h(App)
});

// Listeners

Vue.http.interceptors.push(function(request, next) {
  // console.log(request.method + " : " + JSON.stringify(request.url) + " / headers : " + JSON.stringify(request.headers));
  var access_token_sba = window.$cookies.get('access_token_sba');

  if (access_token_sba) {
    request.headers.set('Authorization', 'Bearer ' + access_token_sba);
  }

  next(function(response) {
    if(response.status == 401 && !request.url.endsWith('/login')){
      window.$cookies.remove('access_token_sba');
      console.log("Redirect to login");
      router.push('/app/login').catch(err => {
        console.error("Can't redirect the user to this path : '/app/login'");
      });
    }
  });

});

// Mount the main component
vm.$mount('#app');
