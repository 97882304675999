<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-19T13:58:33+02:00
-->

<template>
  <div class="d-flex flex-column mx-2">

    <div class="d-flex align-items-center">
      <b-button class="p-0 transparent_button mr-2" @click="goBack()">
        <img src="@/assets/img/png/ico_goback.png" style="width:3em"/>
      </b-button>
      <div class="mainTitle">
        <div id="mtitle">{{ topic.name }}</div>
        <div id="stitle">{{ articles.length }} articles</div>
        <hr>
      </div>
    </div>

    <div class="d-flex align-items-start">
      <div class="mr-3">
        <b-button class="transparent_button m-0 p-0" @click="view = 'grid'">
          <div :class="{'modeButton': true, 'activeMode': view == 'grid'}">
            <img src="@/assets/img/svg/ico_grid.svg" title="grid">
          </div>
        </b-button>
        <b-button class="transparent_button m-0 p-0" @click="view = 'list'">
          <div :class="{'modeButton': true, 'activeMode': view == 'list'}">
            <img src="@/assets/img/svg/ico_list.svg" title="list">
          </div>
        </b-button>
      </div>
      <div v-if="view == 'grid'"  class="d-flex align-items-center ml-auto">
        <span class="mr-2 text-nowrap">Sort by :</span>
        <b-form-select v-model="sorting_by" :options="sortinglist"></b-form-select>
      </div>
    </div>

    <hr v-if="view == 'grid'" class="w-100">

    <div v-if="articles">

      <articlesGrid v-if="view == 'grid'" mode="topic" :no_image="no_image" :articles="sortingArticles" v-on:removeArticle="openRemoveArticle($event.article, $event.index)" v-on:openArticleTo="openArticleTo($event.article, $event.index)"></articlesGrid>

      <articlesList v-if="view == 'list'" mode="topic" :no_image="no_image" :articles="sortingArticles" v-on:removeArticle="openRemoveArticle($event.article, $event.index)" v-on:openArticleTo="openArticleTo($event.article, $event.index)"></articlesList>

    </div>

    <b-modal size="xl" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showAddArticle">
      <b-button class="crossButton" @click="showAddArticle = false">✕</b-button>

      <h3 class="largeTitle">Themes</h3>

      <p>Allows you to add or remove this item from the themes.</p>

      <div v-if="current_article" class="my-3">
        <div class="d-flex flex-wrap">
          <div style="width:33%" v-for="t in theme"  :key="t.shortname">
            <b-form-checkbox v-model="t.value" switch>{{t.name}}</b-form-checkbox>
          </div>
        </div>
      </div>

      <hr>

      <div class="d-flex" id="buttons">
        <b-button class="largeColoredButton mx-auto" @click="showAddArticle = false">CANCEL</b-button>
        <b-button class="largeColoredButton mx-auto" @click="setArticleThemes()">ADD ARTICLE TO THESE THEMES</b-button>
      </div>

    </b-modal>

    <b-modal size="xl" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showRemoveArticle">
      <b-button class="crossButton" @click="showRemoveArticle = false">✕</b-button>

      <p>Do you really want to remove this article from the database ? (of all topics and themes)</p>

      <div v-if="current_article" class="my-3">
        <div class="my-2" v-if="current_article.value.is_validated">
          <strong style="color:red">Warning : </strong><span>This article has already been added to themes. If you remoce it, the user will no longer have access to it.</span>
        </div>
        <p>Name: <span class="largeTitle">{{ current_article.value.title }}</span></p>
      </div>

      <hr>

      <div class="d-flex" id="buttons">
        <b-button class="largeColoredButton mx-auto" @click="showRemoveArticle = false">CANCEL</b-button>
        <b-button class="largeColoredButton mx-auto" @click="removeArticle()">REMOVE </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>

////////////////// Import //////////////////

import Articles from '@/services/articles'
const articlesService = new Articles();

import articlesList from '@/components/articles/articlesList'
import articlesGrid from '@/components/articles/articlesGrid'

////////////////// Functions //////////////////

function getList(self){
  articlesService.getListFromTopic(self.topic.uri).then(function(result) {
    self.articles = result.body.articles;
    self.sources = result.body.sources;

    for (let article of self.articles) {
      // Get source info
      let source = self.sources.find(a => a.uri == article.metadata.source_uri);
      // console.log("source = " + JSON.stringify(source));
      if(source) {
        article.source_name = source.name;
        article.source_country = (source.location)? source.location.country.name : null;
      }

      // article.source_name = source?.name;
      // article.source_country = source?.location?.country?.name;
    }

    self.sorting_by = "date";

    self.loading = false;
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
  });
}

export default {
  name: 'articles',
  props: ['topic','themes'],
  data () {
    return {
      loading: true,
      showAddArticle: false,
      showRemoveArticle: false,
      no_image: null,
      articles: [],
      sources: [],
      current_article: null,
      theme: null,
      sortinglist: [],
      sorting_by: null,
      view: "grid"
    }
  },
  components: {
    articlesList,
    articlesGrid
  },
  computed: {
    sortingArticles: function() {
      console.log(this.sorting_by);

      switch (this.sorting_by) {
        case 'date':
          return this.articles.sort((a,b) => {return (a.metadata.date <  b.metadata.date)? 1 : -1;});
        case 'media':
          return this.articles.sort((a,b) => {return (a.source_name <  b.source_name)? -1 : 1;});
        case 'language':
          return this.articles.sort((a,b) => {return (a.metadata.language <  b.metadata.language)? -1 : 1;});
        default:
          return 0
      }
    },
  },
  created: function() {
    this.sortinglist = [
      { value: 'date', text: "Date"},
      { value: 'media', text: "Media"},
      { value: 'language', text: "Language"}
    ]

    this.no_image = require('@/assets/img/png/ico_no_image.png');

    getList(this);
  },
  methods: {
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR');
    },
    goBack() {
      this.$emit('goBack')
    },
    addValue(list, value){
      if(!list.includes(value) && value && value != '') {
        list.push(value)
        this.theme = null;
      }
    },
    removeValue(list, index){
      if(list.length > index) list.splice(index,1)
    },
    openRemoveArticle(article, index){
      this.current_article = {index: index, value: article};
      this.showRemoveArticle = true;
    },
    removeArticle(){
      let self = this;
      let article = this.current_article.value;
      let index = this.current_article.index;
      articlesService.remove(article.uri).then(function(result) {
        self.articles.splice(index, 1);
        self.$bvToast.toast("Article have been successfully removed", self.__toaster_opts('success', 'Success'))
        self.showRemoveArticle = false;
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });

    },
    openArticleTo(article, index){
      this.current_article = {index: index, value: article};

      let themes_ids = article.themes_ids;
      this.theme = this.themes.map(a => {
        return {
          id: a._id,
          name: a.name_en,
          value: (themes_ids.includes(a._id))? true : false
        }
      })

      this.showAddArticle = true;
    },
    setArticleThemes(){
      let self = this;
      let article = this.current_article.value;
      let index = this.current_article.index;

      // Get checkbox results
      let themes = this.theme.filter(a => a.value == true).map(a => a.id);

      articlesService.addToThemes([article.uri], this.topic.uri, themes, true).then(function(result) {
        self.$set(self.articles[index], 'is_validated', result.body.articles[0].is_validated)
        self.$set(self.articles[index], 'themes_ids', result.body.articles[0].themes_ids)
        self.$bvToast.toast("Articles have been added and/or removed from these themes", self.__toaster_opts('success', 'Success'))
        self.showAddArticle = false;
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });
    },
    openArticle(article){
      if(!article.article_url) return this.$bvToast.toast('Missing article url', this.__toaster_opts('error', 'Error'))
      window.open(article.article_url, '_blank');
    }
  }
}

</script>

<style lang="scss">

@import '@/assets/css/common.scss';



</style>
