<!--
@Author: laborde
@Date:   2022-05-17T09:22:23+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-09-26T09:45:08+02:00
-->

<template>
  <div>
    <h3 class="largeTitle">User statistics</h3>

    <div v-if="!loading && stats">

      <h4 class="largeTitle">Themes</h4>

      <b-table :items="stats.themes" :fields="fields_themes">
        <template v-slot:cell(openings)="data">
          <div class="d-flex flex-column">
            <div class="">{{ data.item.nb_openings }} openings ({{ data.item.nb_openings_pct }}%)</div>
            <div class="">Last opening : {{ toStringDate(data.item.last_opening) }}</div>
          </div>
        </template>

      </b-table>


      <h4 class="largeTitle">Articles</h4>

      <h6 class="largeTitle">Opened articles (redirection to media article) :</h6>
      <div><span class="mr-2">Number of time :</span><span class="largeTitle">{{ stats.articles.nb_redirection }}</span></div>
      <div><span class="mr-2">Last Time :</span><span class="largeTitle">{{ toStringDate(stats.articles.last_redirection) }}</span></div>

      <h6 class="largeTitle mt-3">Articles loading (get articles from API) :</h6>
      <div><span class="mr-2">Number of time :</span><span class="largeTitle">{{ stats.articles.nb_load }}</span></div>
      <div><span class="mr-2">Last Time :</span><span class="largeTitle">{{ toStringDate(stats.articles.last_load) }}</span></div>

    </div>


  </div>
</template>

<script>

////////////////// Import //////////////////

import Users from '@/services/users'
const usersService = new Users();

import Themes from '@/services/themes'
const themesService = new Themes();

////////////////// Functions //////////////////

function getStats(self, callback){
  usersService.getStats(self.id).then(function(result) {
    self.stats = result.body.user_stats;
    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
    callback();
  });
}

function getThemesList(self, callback){
  themesService.getList(false).then(function(result) {
    self.themes = result.body.themes;
    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    callback();
  });
}

export default {
  name: 'plan',
  props: ['id'],
  data () {
    return {
      loading: true,
      stats: null,
      themes: [],
      fields_themes: []
    }
  },
  computed: {

  },
  created: function() {
    var self = this;

    this.fields_themes = [
      { key: 'shortname', label: 'Shortname', sortable: true },
      { key: 'name', label: 'Name', sortable: true },

      { key: 'openings', label: 'openings', sortable: true },
      { key: 'articles.nb_redirection', label: 'Articles "opened"', sortable: true },


    ]

    getStats(self, function () {
      getThemesList(self, function () {
        if(self.stats) {
          let nb_openings_total = self.stats.themes.map(a => a.nb_openings).reduce((a,b) => a + b, 0);
          console.log("nb_openings_total= " + nb_openings_total);

          for (var i = 0; i < self.stats.themes.length; i++) {
            let theme = self.stats.themes[i];
            let index = self.themes.findIndex(a => '' + a._id == '' + self.stats.themes[i].id);
            if(index > -1) {
              self.stats.themes[i].shortname = self.themes[index].shortname;
              self.stats.themes[i].name = self.themes[index].name_en;
            }
            self.stats.themes[i].nb_openings_pct = parseInt(theme.nb_openings * 100 / nb_openings_total);
          }
        }

        self.loading = false;
      });
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR') + "\n" + (new Date(date)).toLocaleTimeString('fr-FR');
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';


</style>
