<!--
@Author: laborde
@Date:   2022-04-13T13:14:50+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-10-03T09:24:57+02:00
-->

<template>
  <div id="main">

    <div class="d-flex align-items-center">
      <b-button class="p-0 transparent_button mr-2" @click="goBackToUsers()">
        <img src="@/assets/img/png/ico_goback.png" style="width:3em"/>
      </b-button>
      <div class="mainTitle">
        <div id="mtitle">Admins</div>
        <div id="stitle">List of admins</div>
        <hr>
      </div>
    </div>


    <b-table :items="admins" :fields="fields">
      <template v-slot:cell(username)="data">
        <span class="largeTitle">{{ data.item.username }}</span>
      </template>
      <template v-slot:cell(activated)="data">
        {{ (data.item.activated)? 'YES' : 'NO' }}
      </template>
      <template v-slot:cell(created_at)="data">
        {{ toStringDate(data.item.created_at) }}
      </template>
      <template v-slot:cell(actions)="data">
        <div class="d-flex">
          <b-button class="largeColoredButton mx-auto" @click="current_admin_id = data.item._id;showUserCard = true">DISPLAY</b-button>
        </div>
      </template>
    </b-table>


    <b-modal size="xl" hide-header hide-footer no-close-on-backdrop no-close-on-esc v-model="showUserCard">
      <b-button class="crossButton" @click="current_admin_id = null;showUserCard = false">✕</b-button>

      <adminCard :id="current_admin_id" @updated="adminUpdated($event)" @close="current_admin_id = null;showUserCard = false"></adminCard>

    </b-modal>

  </div>
</template>

<script>

////////////////// Import //////////////////

import adminCard from './admin'

import Admins from '@/services/admins'
const adminsService = new Admins();

////////////////// Functions //////////////////

function getAdminsList(self, callback){
  adminsService.getList().then(function(result) {
    self.admins = result.body.admins;
    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
    callback();
  });
}

export default {
  name: 'Home',
  data () {
    return {
      loading: true,
      showUserCard: false,
      fields: [],
      admins: null,
      current_admin_id: null
    }
  },
  components: {
    adminCard
  },
  created: function() {
    var self = this;

    this.fields = [
      { key: 'username', label: 'Username', sortable: true },
      { key: 'activated', label: 'Activated', sortable: true },
      { key: 'created_at', label: 'Created at', sortable: true },
      { key: 'actions', label: '' }
    ]

    getAdminsList(self, function () {
      self.loading = false;
    });
  },
  methods: {
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR') + "\n" + (new Date(date)).toLocaleTimeString('fr-FR');
    },
    goBackToUsers(){
      this.$router.push({ name: 'users' })
    },
    adminUpdated(data){
      let index = this.admins.findIndex(a => a._id == data.admin._id);
      if(index > -1) this.$set(this.admins, index, data.admin)
      this.$bvToast.toast("The admin user have been updated !", this.__toaster_opts('success', 'Success'))
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';


</style>
