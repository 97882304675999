<!--
@Author: laborde
@Date:   2022-03-23T09:53:46+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-18T10:29:48+02:00
-->

<template>
  <div id="main">
    <!-- <h1>NOT FINISHED (DO NOT USE IT) !!!</h1> -->

    <div class="mainTitle pl-0">
      <div id="mtitle">Next Newsletter</div>
      <div>This newsletter will be send the next monday.</div>
      <hr>
    </div>

    <h4 class="largeTitle">Articles</h4>

    <div v-if="articles">
      <articlesList mode="newsletter" :no_image="no_image" :articles="articles" @addToNewsletter="addToNewsletter($event.article, $event.index)"></articlesList>
    </div>

    <!-- <b-button class="largeColoredButton" @click="testNotif()">Test the newsletter</b-button> -->

    <hr>

    <h4 class="largeTitle">History</h4>

    <b-table class="mt-3" :items="history" :fields="['sent_at','articles']">
      <template v-slot:cell(sent_at)="data">
        {{ toStringDate(data.item.sent_at) }}
      </template>
      <template v-slot:cell(articles)="data">
       {{ data.item.articles.length }} articles sent
      </template>
    </b-table>

  </div>
</template>

<script>

////////////////// Import //////////////////

import Newsletters from '@/services/newsletters'
const newslettersService = new Newsletters();

import Articles from '@/services/articles'
const articlesService = new Articles();

import articlesList from '@/components/articles/articlesList'

////////////////// Functions //////////////////

function getNewsLetter(self, callback) {
  newslettersService.getCurrent().then(function(result) {
    self.newsletter = result.body.news;
    newslettersService.getHistory().then(function(result) {
      self.history = result.body.history;
      callback();
    }, function(err) {
      console.error("err = " + JSON.stringify(err));
      callback();
    });
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    callback();
  });
}

function getArticles(self, uris, callback){
  articlesService.getListFromURIs(uris).then(function(result) {
    self.articles = result.body.articles;
    self.sources = result.body.sources;

    for (let article of self.articles) {
      let source = self.sources.find(a => a.uri == article.metadata.source_uri);
      if(source) {
        article.source_name = source.name;
        article.source_country = (source.location)? source.location.country.name : null;
      }

      // Newsletter
      article.inNewsletter = self.newsletter.articles.findIndex(a => a.uri == article.uri) > -1;

    }

    callback();
  }, function(err) {
    console.error("err = " + JSON.stringify(err));
    self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
    callback();
  });
}

export default {
  name: 'newsletters',
  data () {
    return {
      loading: true,
      newsletter: null,
      no_image: null,
      articles: [],
      sources: [],
      history: []
    }
  },
  components: {
    articlesList
  },
  computed: {

  },
  created: function() {
    let self = this;

    this.no_image = require('@/assets/img/png/ico_no_image.png');

    getNewsLetter(self, function() {
      let uris = self.newsletter.articles.map(a => a.uri);
      if(uris && uris.length > 0) {
        getArticles(self, uris, function() {
          self.loading = false;
        })
      } else {
        self.loading = false;
      }

    })
  },
  methods: {
    toStringDate(date) {
      if(date == null) return "";
      return "" + (new Date(date)).toLocaleDateString('fr-FR') + "\n" + (new Date(date)).toLocaleTimeString('fr-FR');
    },
    addToNewsletter(article, index){
      let self = this;
      newslettersService.addArticleToCurrent(article.uri).then(function(result) {
        if(result.body.status == "removed") {
          article.inNewsletter = false;
          self.$bvToast.toast("Article removed from the newsletter", self.__toaster_opts('success', 'Success'))
        } else {
          article.inNewsletter = true;
          self.$bvToast.toast("Article added to the newsletter", self.__toaster_opts('success', 'Success'))
        }

        self.$set(self.articles, index, article)

      }, function(err) {
        console.error("err = " + JSON.stringify(err));
        self.$bvToast.toast(err.body.message, self.__toaster_opts('error', 'Error'))
      });
    },
    testNotif() {
      let self = this;
      newslettersService.testNotif().then(function(result) {
        self.$bvToast.toast("Newsletter send !", self.__toaster_opts('success', 'Success'))
      }, function(err) {
        console.error("err = " + JSON.stringify(err));
      });
    }
  }
}

</script>

<style lang="scss">

@import '@/assets/css/common.scss';


</style>
