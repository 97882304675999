/**
 * @Author: laborde
 * @Date:   2022-03-23T10:01:49+01:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-06-06T11:25:17+02:00
 */

'use strict'

import Vue from 'vue'

export default class genericClass {

  getList() {
    return Vue.http.get(Vue.prototype.$_url + "/teams/list");
  }

  get(id) {
    return Vue.http.get(Vue.prototype.$_url + "/teams/" + id);
  }

  create(team) {
    return Vue.http.put(Vue.prototype.$_url + "/teams", {team : team});
  }

  update(team) {
    return Vue.http.post(Vue.prototype.$_url + "/teams", {team : team});
  }
}
